import GetAccount from "../api/GetAccount";

import { useState } from "react";
import { IAccount } from "../types/interface";
import { delay } from "q";

const useAccount = () => {
	const [account, setAccount] = useState<IAccount | null>(null);
	const [loadingAccount, setLoading] = useState(true);
	const [errorAccount, setError] = useState<Error | string>("");
	const fetchAccount = async (account: string) => {
		try {
			delay(1000);
			const response = await GetAccount(account);
			if (response) {
				setAccount(response.data);
			}
		} catch (err) {
			setError(err instanceof Error ? err : new Error(String(err)));
		} finally {
			setLoading(false);
		}
	};

	return { loadingAccount, errorAccount, account, fetchAccount };
};

export default useAccount;
