import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
	Route,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromElements,
} from "react-router-dom";
import Dashboard from "./page/Dashboard";
import { Buffer } from "buffer";
import PoolPage from "./page/PoolPage";
import AccountPage from "./page/AccountPage";
import { PoolDataProvider } from "./providers/PoolDataProvider";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { initializeGA } from "./contracts/initializeGA";
import { AnalyticsProvider } from "./providers/AnalyticsProvider";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
	useLocation,
	useNavigationType,
	createRoutesFromChildren,
	matchRoutes,
} from "react-router-dom";
Sentry.init({
	dsn: "https://72f08900f7ebc67466e455c94c427c90@o4506949273976832.ingest.us.sentry.io/4507051176558592",
	integrations: [
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect: useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
		Sentry.replayIntegration(),
	],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	tracesSampleRate: 1.0,

	// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});
const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" element={<App />}>
			<Route index element={<Dashboard />} />
			<Route
				path=":poolId"
				element={
					<PoolDataProvider>
						<PoolPage />
					</PoolDataProvider>
				}
				loader={async ({ params }) => {
					const { poolId } = params;
					const url = `${process.env.REACT_APP_BASE_URL}pools/${poolId}`;
					const response = await fetch(url);
					if (response.status !== 200) {
						return null;
					} else {
						return response;
					}
				}}
			/>
			<Route path="/account" element={<AccountPage />} />
		</Route>
	)
);

window.Buffer = Buffer;
const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	// <React.StrictMode>
	<TonConnectUIProvider
		manifestUrl={`https://tonk-finance-web.onrender.com/tonconnect-manifest.json`}
	>
		<AnalyticsProvider>
			<RouterProvider router={router} />
		</AnalyticsProvider>
	</TonConnectUIProvider>
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
initializeGA();
reportWebVitals();
