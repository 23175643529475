import * as React from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CircularProgress } from "@mui/material";
import { Col, Row, StyledLink } from "../../../utils/styledComponents";
import LaunchIcon from "@mui/icons-material/Launch";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ButtonRectangular from "../../CommonComponents/ButtonRectangular";
interface ITxModal {
  open: boolean;
  handleClose: () => void;
  txLoading: boolean;
}
const TxModal: React.FC<ITxModal> = ({ open, handleClose, txLoading }) => {
  return (
    <Modal
      open={open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Col
        sx={{
          backgroundColor: "background.paper",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // width: "30rem",
          alignItems: "center",
          textAlign: "center",
          padding: "2rem",
          borderRadius: "25px",
          minWidth: "14rem",
          //   border: "solid",
          //   borderColor: "error.main",
          justifyContent: "space-evenly",
          gap: 1,
        }}
      >
        {txLoading ? (
          <>
            <CircularProgress size={100} />
            <Typography id="modal-modal-title" variant="h2">
              Your transaction is being processed
            </Typography>
            <Typography
              id="modal-modal-description"
              variant="subtitle2"
              sx={{ mt: 2 }}
            >
              {`Don't close the application`}
            </Typography>
          </>
        ) : (
          <>
            <CheckCircleIcon sx={{ fontSize: "3rem", color: "#33CC99" }} />
            <Typography id="modal-modal-title" variant="h2">
              Transaction complete
            </Typography>
            <Typography id="modal-modal-title" variant="subtitle2">
              Due to Testnet speed, funds should be visible within 1 minute
            </Typography>
            <Row align="center">
              <StyledLink
                to={`https://testnet.tonviewer.com/transaction/${"bd16aa9967cbb7f9f5f20ddae86419ab9e1c23fea5f74a7c56a93ebe97a94361"}`}
                target={"_blank"}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="subtitle2" sx={{ textAlign: "left" }}>
                  View transaction here
                </Typography>

                <LaunchIcon sx={{ marginLeft: "0.5rem" }} />
              </StyledLink>
            </Row>
            <ButtonRectangular
              text="Close"
              onClick={handleClose}
              disabled={false}
            />
          </>
        )}
      </Col>
    </Modal>
  );
};
export default TxModal;
