import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { IBoxProps } from "../types/interface";
import { Link, NavLink, NavLinkProps } from "react-router-dom";

export const Row = styled(Box)<IBoxProps>(({ theme, align, justify }) => ({
	display: "flex",
	flexDirection: "row",
	alignItems: align,
	justifyContent: justify,
}));
export const Col = styled(Box)<IBoxProps>(({ theme, align, justify }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: align,
	justifyContent: justify,
}));
export const StyledLink = styled(NavLink)<NavLinkProps>(({ theme }) => ({
	textDecoration: "none",
}));
export const StyledFooterLink = styled(Link)<NavLinkProps>(({ theme }) => ({
	textDecoration: "none",
}));
