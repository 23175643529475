import { Address, TonClient } from "ton";

const CheckContractDeployed = async (vaultAddress: string) => {
	const client = new TonClient({
		endpoint: "https://testnet.toncenter.com/api/v2/jsonRPC",
		apiKey: process.env.REACT_APP_TONCENTER_API_KEY,
	});
	try {
		const result = await client.isContractDeployed(Address.parse(vaultAddress));
		console.log(result);
		return result;
	} catch (err) {
		return Promise.reject(err);
	}
};

export default CheckContractDeployed;
