import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
	IJettonValue,
	IJettonsAvailable,
	ITabPanelProps,
} from "../../../types/interface";
import { useContext, useEffect, useState } from "react";
import DepositPairWrapper from "./DepositPairWrapper";
import { useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import DepositLPWrapper from "./DepositLPWrapper";
import WithdrawWrapper from "./WithdrawWrapper";
import { PoolDataContext } from "../../../providers/PoolDataProvider";
import Hints from "./Hints";
import { Col, Row } from "../../../utils/styledComponents";
import Fees from "./Fees";
import { Button, Divider } from "@mui/material";
import { UserWalletsContext } from "../../../page/PoolPage";
import { fromNano } from "ton-core";
import usePrice from "../../../hooks/useGetPrice";
import { calculateToken1, calculateToken2 } from "../../../utils/calculatePair";
import { getTestnetJettons } from "../../../contracts/getTestnetJettons";
import { JettonWalletContext } from "../../../providers/JettonWalletProvider";
import RefreshIcon from "@mui/icons-material/Refresh";
import { getTxByBOC } from "../../../api/GetUserTransactions";
import TxModal from "./TxModal";
import { AnalyticsContext } from "../../../providers/AnalyticsProvider";
import { logEvent } from "@firebase/analytics";
const CustomTabPanel = (props: ITabPanelProps) => {
	const { children, value, index, pool, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			// style={{ height: "20rem" }}
			{...other}
		>
			{value === index && (
				<Col sx={{ p: 3, paddingTop: 0, gap: 2 }}>
					{children}
					<Divider />
					<Fees />
					<Divider />
					<Hints />
				</Col>
			)}
		</div>
	);
};

const a11yProps = (index: number) => {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
};

const ActionTabs = () => {
	const [tonConnectUI] = useTonConnectUI();
	const wallet = useTonWallet();
	const poolContext = useContext(PoolDataContext);
	const walletContext = useContext(UserWalletsContext);
	const jettonWallet = useContext(JettonWalletContext);
	const analyticsContext = useContext(AnalyticsContext);
	const { price } = usePrice();
	const [value, setValue] = useState(0);
	const [fetching, setFetching] = useState(false);
	const [refreshLocked, setRefreshLocked] = useState(false);
	const [txLoading, setTxLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => {
		if (wallet && jettonWallet) {
			jettonWallet.fetchOnTrigger(wallet.account.address, setFetching);
		}
		setOpen(false);
	};
	const handleLoading = (value: boolean) => setTxLoading(value);
	const [jettonsAvailable, setJettonsAvailable] = useState<IJettonsAvailable>({
		token1: 0,
		token2: 0,
		lp: 0,
		withdraw: 0,
	});
	const [jettonsInput, setJettonsInput] = useState<IJettonsAvailable>({
		token1: 0,
		token2: 0,
		lp: 0,
		withdraw: 0,
	});
	const handleModal = async (result: string) => {
		if (result && wallet && jettonWallet) {
			handleLoading(true);
			handleOpen();

			const temp = await getTxByBOC(result, wallet.account.address);
			console.log(temp);
			if (temp) {
				handleLoading(false);
			}
			return temp;
		}
	};

	const handleGetJettons = async () => {
		const transaction = getTestnetJettons();
		try {
			const result = await tonConnectUI.sendTransaction(transaction);
			if (result) {
				handleModal(result.boc);
				if (analyticsContext) {
					logEvent(analyticsContext.analytics, "contract", {
						contract: "LP_TO_VAULT",
						status: "Success",
						errMessage: "none",
					});
				}
			}

			return result;
		} catch (err) {
			const error = err as Error;
			throw new Error(error.message);
		}
	};
	const handleChangePair = (value: IJettonValue) => {
		console.log(value);
		setJettonsInput((prevState) => ({
			...prevState,
			[value.name]: value.amount,
		}));
	};
	const handleChangeToken1 = (value: IJettonValue) => {
		console.log(value);
		if (isNaN(value.amount)) {
			setJettonsInput((prevState) => ({
				...prevState,
				token1: value.amount,
				token2: value.amount,
			}));
		} else {
			if (price && poolContext && poolContext.poolData) {
				const token2Calculated = calculateToken1(
					price,
					value.amount,
					poolContext.poolData.token1.symbol,
					poolContext.poolData.token2.symbol
				);
				if (token2Calculated) {
					setJettonsInput((prevState) => ({
						...prevState,
						token1: value.amount,
						token2: token2Calculated,
					}));
				}
			}
		}
	};
	const handleChangeToken2 = (value: IJettonValue) => {
		if (isNaN(value.amount)) {
			setJettonsInput((prevState) => ({
				...prevState,
				token1: value.amount,
				token2: value.amount,
			}));
		} else {
			if (price && poolContext && poolContext.poolData) {
				const token2Calculated = calculateToken2(
					price,
					value.amount,
					poolContext.poolData.token1.symbol,
					poolContext.poolData.token2.symbol
				);
				if (token2Calculated) {
					setJettonsInput((prevState) => ({
						...prevState,
						token1: token2Calculated,
						token2: value.amount,
					}));
				}
			}
		}
	};
	const handleJettonAvailable = (name: string, amount: number) => {
		setJettonsAvailable((prevState) => ({
			...prevState,
			[name]: amount,
		}));
	};

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};
	const handleLockRefresh = () => {
		if (wallet) {
			jettonWallet?.fetchOnTrigger(wallet.account.address, setFetching);
			setRefreshLocked(true);
			setTimeout(() => {
				setRefreshLocked(false);
			}, 2500);
		}
	};
	useEffect(() => {
		console.log(wallet);
		console.log(walletContext);
		if (wallet) {
			if (walletContext?.userWallets.lp) {
				const parsedNumber = fromNano(walletContext.userWallets.lp.balance);
				handleJettonAvailable("lp", parseFloat(parsedNumber));
			}
			if (walletContext?.userWallets.token1) {
				const parsedNumber = fromNano(walletContext.userWallets.token1.balance);
				handleJettonAvailable("token1", parseFloat(parsedNumber));
			}
			if (walletContext?.userWallets.token2) {
				const parsedNumber = fromNano(walletContext.userWallets.token2.balance);
				handleJettonAvailable("token2", parseFloat(parsedNumber));
			}
			if (walletContext?.userWallets.vault) {
				const parsedNumber = fromNano(walletContext.userWallets.vault.balance);
				handleJettonAvailable("withdraw", parseFloat(parsedNumber));
			}
		} else {
			handleJettonAvailable("lp", 0);
			handleJettonAvailable("token1", 0);
			handleJettonAvailable("token2", 0);
			handleJettonAvailable("withdraw", 0);
		}
	}, [wallet, walletContext]);
	useEffect(() => {
		if (wallet) {
			const interval = setInterval(async () => {
				jettonWallet?.fetchOnTrigger(wallet.account.address, setFetching);
			}, 60000);
			return () => clearInterval(interval);
		}
	}, [wallet]);

	return (
		<>
			<TxModal open={open} handleClose={handleClose} txLoading={txLoading} />
			<Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<Tabs
						centered
						value={value}
						onChange={handleChange}
						aria-label="basic tabs example"
					>
						<Tab
							sx={{ textTransform: "none" }}
							label="Deposit pair"
							{...a11yProps(0)}
						/>
						<Tab sx={{ textTransform: "none" }} label="LP" {...a11yProps(1)} />
						<Tab
							sx={{ textTransform: "none" }}
							label="Vault"
							{...a11yProps(2)}
						/>
					</Tabs>
				</Box>
				<Row align="center" justify="flex-end" sx={{ paddingRight: "1rem" }}>
					<Button
						disabled={refreshLocked}
						onClick={handleLockRefresh}
						sx={{ textTransform: "none", color: "#940686" }}
						endIcon={
							<RefreshIcon sx={{ color: refreshLocked ? "gray" : "#940686" }} />
						}
					>
						Refresh
					</Button>
				</Row>
				<CustomTabPanel value={value} index={0} pool={poolContext?.poolData}>
					<DepositPairWrapper
						handleChangeToken1={handleChangeToken1}
						handleChangeToken2={handleChangeToken2}
						jettonsAvailable={jettonsAvailable}
						jettonsInput={jettonsInput}
						userAddress={wallet?.account.address}
						fetching={fetching}
						handleModal={handleModal}
					/>
					<Button
						sx={{ textTransform: "none", background: "transparent" }}
						onClick={handleGetJettons}
					>
						Not enough jettons? Click here!
					</Button>
				</CustomTabPanel>

				<CustomTabPanel value={value} index={1} pool={poolContext?.poolData}>
					<DepositLPWrapper
						handleChangePair={handleChangePair}
						jettonsAvailable={jettonsAvailable}
						jettonsInput={jettonsInput}
						userAddress={wallet?.account.address}
						fetching={fetching}
						handleModal={handleModal}
					/>
				</CustomTabPanel>

				<CustomTabPanel value={value} index={2} pool={poolContext?.poolData}>
					<WithdrawWrapper
						handleChangePair={handleChangePair}
						jettonsAvailable={jettonsAvailable}
						jettonsInput={jettonsInput}
						userAddress={wallet?.account.address}
						fetching={fetching}
						handleModal={handleModal}
					/>
				</CustomTabPanel>
			</Box>
		</>
	);
};
export default ActionTabs;
