import { Col, Row } from "../../../../utils/styledComponents";
import { Typography } from "@mui/material";
import { IFactors } from "../../../../utils/scoreMocks";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  KeyboardDoubleArrowUp,
} from "@mui/icons-material";
import CustomTooltip from "../../../CommonComponents/CustomTooltip";
interface IData {
  factors: IFactors;
}
const SafetyScoreSnippets: React.FC<IData> = ({ factors }) => {
  return (
    <Col align="center">
      {factors.impLoss && (
        <Row align="center">
          {factors.impLoss.arrowDirection === "up" ? (
            <KeyboardArrowUp sx={{ color: factors.impLoss.color }} />
          ) : factors.impLoss.arrowDirection === "doubleup" ? (
            <KeyboardDoubleArrowUp sx={{ color: factors.impLoss.color }} />
          ) : (
            <KeyboardArrowDown sx={{ color: factors.impLoss.color }} />
          )}
          <Typography
            variant="body1"
            sx={{ paddingLeft: "0.5rem", paddingRight: "0.5rem" }}
          >
            {factors.impLoss.title}
          </Typography>
          <CustomTooltip text={factors.impLoss.description} />
        </Row>
      )}
      {factors.liquidity && (
        <Row align="center">
          {factors.liquidity.arrowDirection === "up" ? (
            <KeyboardArrowUp sx={{ color: factors.liquidity.color }} />
          ) : factors.liquidity.arrowDirection === "doubleup" ? (
            <KeyboardDoubleArrowUp sx={{ color: factors.liquidity.color }} />
          ) : (
            <KeyboardArrowDown sx={{ color: factors.liquidity.color }} />
          )}
          <Typography
            variant="body1"
            sx={{ paddingLeft: "0.5rem", paddingRight: "0.5rem" }}
          >
            {factors.liquidity.title}
          </Typography>
          <CustomTooltip text={factors.liquidity.description} />
        </Row>
      )}
      {factors.mCap && (
        <Row align="center">
          {factors.mCap.arrowDirection === "up" ? (
            <KeyboardArrowUp sx={{ color: factors.mCap.color }} />
          ) : factors.mCap.arrowDirection === "doubleup" ? (
            <KeyboardDoubleArrowUp sx={{ color: factors.mCap.color }} />
          ) : (
            <KeyboardArrowDown sx={{ color: factors.mCap.color }} />
          )}
          <Typography
            variant="body1"
            sx={{ paddingLeft: "0.5rem", paddingRight: "0.5rem" }}
          >
            {factors.mCap.title}
          </Typography>
          <CustomTooltip text={factors.mCap.description} />
        </Row>
      )}
      {factors.platform && (
        <Row align="center">
          {factors.platform.arrowDirection === "up" ? (
            <KeyboardArrowUp sx={{ color: factors.platform.color }} />
          ) : factors.platform.arrowDirection === "doubleup" ? (
            <KeyboardDoubleArrowUp sx={{ color: factors.platform.color }} />
          ) : (
            <KeyboardArrowDown sx={{ color: factors.platform.color }} />
          )}
          <Typography
            variant="body1"
            sx={{ paddingLeft: "0.5rem", paddingRight: "0.5rem" }}
          >
            {factors.platform.title}
          </Typography>
          <CustomTooltip text={factors.platform.description} />
        </Row>
      )}
    </Col>
  );
};

export default SafetyScoreSnippets;
