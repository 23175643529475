import { Address, TonClient } from "ton";
const GetVaultSupply = async (vaultAddress: string) => {
	const client = new TonClient({
		endpoint: "https://testnet.toncenter.com/api/v2/jsonRPC",
		apiKey: process.env.REACT_APP_TONCENTER_API_KEY,
	});
	try {
		const result = await client.runMethod(
			Address.parse(vaultAddress),
			"get_vault_data"
		);
		const stack = result.stack;
		const supplyRaw = stack.skip(6);
		const supplyParsed = supplyRaw.readBigNumber();
		console.log(supplyParsed);

		return supplyParsed;
	} catch (err) {
		return Promise.reject(err);
	}
};

export default GetVaultSupply;
