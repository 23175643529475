import { toNano } from "ton-core";
import { TestnetJetton } from "@tonk-finance/sdk";
export const getTestnetJettons = () => {
	const body = TestnetJetton.createMint100Body();
	const transaction = {
		validUntil: Date.now() + 5 * 60 * 1000,
		messages: [
			{
				//tkUSDC
				address: "EQDYBu-mF0x8sOhtV08x5ahY_MOJ4dFrP6_CngW5_mk0qmuz", //jetton minter address
				amount: toNano("0.1").toString(), // for commission fees, excess will be returned
				payload: body,
			},
			{
				//tkUSDT
				address: "EQArPYNYOkdKWcJgsZ8MVL6E-sNY4gOCyW0XluIVITCPgl_t", //jetton minter address
				amount: toNano("0.1").toString(), // for commission fees, excess will be returned
				payload: body,
			},
			{
				//tkWTON
				address: "EQCPfVyjKVvMXH3TP9VJm_1JF0b3LtWCoNGx4yg9ahOZ73q2", //jetton minter address
				amount: toNano("0.1").toString(), // for commission fees, excess will be returned
				payload: body,
			},
			{
				//tkTONK
				address: "EQBBgnYauUGf-4kQbeXQ-kHOnhOsxC-a3mfc_Js-RtKfD2um", //jetton minter address
				amount: toNano("0.1").toString(), // for commission fees, excess will be returned
				payload: body,
			},
		],
	};
	return transaction;
};
