import { SvgIcon } from "@mui/material";

const LogoLight = ({ width, height }) => {
	return (
		<SvgIcon sx={{ width: width, height: height }}>
			<svg
				width="216"
				height="120"
				viewBox="0 0 216 120"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M100.012 45.5645C111.124 51.9803 111.124 68.0197 100.012 74.4355L25.003 117.742C13.8906 124.158 0 116.138 0 103.306V16.6935C0 3.86199 13.8906 -4.15773 25.003 2.25805L100.012 45.5645Z"
					fill="url(#paint0_linear_734_26)"
				/>
				<path
					d="M139.433 60.9168L136.725 60.7501V66.5008C136.725 67.2509 136.836 67.7926 137.058 68.126C137.308 68.4593 137.753 68.626 138.392 68.626C139.058 68.626 139.558 68.6538 139.892 68.7094C140.253 68.7372 140.628 68.8622 141.017 69.0844C141.656 69.4456 141.975 70.3762 141.975 71.8764C141.975 73.6266 141.586 74.6962 140.809 75.0851C140.392 75.2796 140.017 75.4046 139.683 75.4602C139.378 75.488 138.919 75.5019 138.308 75.5019C135.419 75.5019 133.224 74.849 131.724 73.5433C130.224 72.2376 129.474 70.029 129.474 66.9175V60.7501C128.946 60.8334 128.377 60.8751 127.765 60.8751C127.154 60.8751 126.64 60.639 126.223 60.1667C125.835 59.6944 125.64 58.8887 125.64 57.7497C125.64 56.6107 125.71 55.7912 125.848 55.2911C125.987 54.7633 126.182 54.3882 126.432 54.166C126.876 53.8048 127.432 53.6242 128.099 53.6242L129.474 53.7909V49.4987C129.474 48.8598 129.488 48.3875 129.516 48.0819C129.571 47.7763 129.71 47.4429 129.932 47.0818C130.293 46.3872 131.363 46.04 133.141 46.04C135.03 46.04 136.155 46.5539 136.516 47.5818C136.655 48.0263 136.725 48.7348 136.725 49.7071V53.7909C137.947 53.6798 138.864 53.6242 139.475 53.6242C140.114 53.6242 140.586 53.652 140.892 53.7076C141.225 53.7354 141.6 53.8604 142.017 54.0826C142.739 54.4438 143.101 55.5133 143.101 57.2913C143.101 59.0415 142.725 60.1111 141.975 60.5001C141.559 60.6945 141.184 60.8195 140.85 60.8751C140.545 60.9029 140.072 60.9168 139.433 60.9168Z"
					fill="#EDEDED"
				/>
				<path
					d="M144.776 64.5422C144.776 61.2085 145.943 58.4581 148.277 56.2912C150.611 54.1243 153.278 53.0408 156.278 53.0408C159.278 53.0408 161.931 54.1243 164.237 56.2912C166.543 58.4304 167.696 61.1668 167.696 64.5005C167.696 66.8064 167.099 68.8622 165.904 70.6679C164.709 72.4459 163.251 73.7655 161.528 74.6268C159.834 75.4602 158.07 75.8769 156.236 75.8769C154.403 75.8769 152.625 75.4324 150.902 74.5434C149.18 73.6266 147.721 72.2931 146.527 70.5429C145.36 68.7649 144.776 66.7647 144.776 64.5422ZM153.486 67.5426C154.375 68.2093 155.278 68.5427 156.195 68.5427C157.111 68.5427 158.028 68.1954 158.945 67.5009C159.862 66.8064 160.32 65.7785 160.32 64.4172C160.32 63.0559 159.889 62.0419 159.028 61.3752C158.167 60.7084 157.236 60.375 156.236 60.375C155.236 60.375 154.305 60.7223 153.444 61.4168C152.583 62.1114 152.152 63.1393 152.152 64.5005C152.152 65.834 152.597 66.848 153.486 67.5426Z"
					fill="#EDEDED"
				/>
				<path
					d="M182.598 53.5409C185.376 53.5409 187.682 54.6105 189.516 56.7496C191.377 58.861 192.308 61.4446 192.308 64.5005V71.8347C192.308 72.4737 192.28 72.9599 192.224 73.2933C192.196 73.5989 192.085 73.9461 191.891 74.335C191.502 75.0851 190.418 75.4602 188.64 75.4602C186.668 75.4602 185.529 74.9462 185.223 73.9183C185.057 73.4461 184.973 72.7376 184.973 71.7931V64.4589C184.973 63.3198 184.64 62.4308 183.973 61.7919C183.334 61.1529 182.459 60.8334 181.348 60.8334C180.264 60.8334 179.375 61.1668 178.681 61.8336C178.014 62.5003 177.681 63.3754 177.681 64.4589V71.8347C177.681 72.4737 177.653 72.9599 177.597 73.2933C177.57 73.5989 177.445 73.9461 177.222 74.335C176.861 75.0851 175.792 75.4602 174.014 75.4602C172.264 75.4602 171.194 75.0851 170.805 74.335C170.611 73.9183 170.486 73.5572 170.43 73.2516C170.402 72.9182 170.388 72.432 170.388 71.7931V57.0413C170.388 56.4301 170.402 55.9717 170.43 55.6661C170.486 55.3328 170.624 54.9716 170.847 54.5827C171.236 53.8881 172.305 53.5409 174.055 53.5409C175.75 53.5409 176.792 53.8604 177.181 54.4993C177.459 54.9716 177.597 55.5411 177.597 56.2079C177.709 56.0134 177.959 55.7356 178.348 55.3744C178.737 55.0133 179.112 54.7216 179.473 54.4993C180.417 53.8604 181.459 53.5409 182.598 53.5409Z"
					fill="#EDEDED"
				/>
				<path
					d="M208.578 64.5005L214.12 69.0844C214.815 69.6678 215.246 70.1123 215.412 70.4179C215.607 70.7235 215.704 71.0152 215.704 71.293C215.704 71.9598 215.273 72.8627 214.412 74.0017C213.468 75.1685 212.634 75.7519 211.912 75.7519C211.301 75.7519 210.453 75.3491 209.37 74.5434L208.953 74.2517C208.314 73.7239 207.328 72.8071 205.994 71.5014C204.661 70.1679 203.647 69.2233 202.952 68.6677V71.8764C202.952 72.5154 202.925 73.0016 202.869 73.3349C202.841 73.6405 202.716 74.0017 202.494 74.4184C202.133 75.1407 201.063 75.5019 199.285 75.5019C197.535 75.5019 196.466 75.1268 196.077 74.3767C195.882 73.96 195.757 73.5989 195.702 73.2933C195.674 72.9599 195.66 72.4737 195.66 71.8347V46.8734C195.66 46.2345 195.674 45.7622 195.702 45.4566C195.757 45.1232 195.882 44.7482 196.077 44.3314C196.466 43.5814 197.549 43.2063 199.327 43.2063C200.66 43.2063 201.605 43.4425 202.161 43.9147C202.633 44.3037 202.883 44.9149 202.911 45.7483C202.939 45.9983 202.952 46.3872 202.952 46.9151V60.125C203.452 59.7083 204.203 58.9999 205.203 57.9998C206.231 56.9996 206.995 56.2912 207.495 55.8745L207.995 55.5411C208.967 54.791 209.8 54.416 210.495 54.416C211.19 54.416 211.981 54.9855 212.87 56.1245C213.787 57.2358 214.245 58.1109 214.245 58.7498C214.245 59.3888 213.731 60.1389 212.704 61.0001L208.578 64.5005Z"
					fill="#EDEDED"
				/>
				<path
					d="M51.0403 89.0523C39.3326 90.3112 29.1039 81.3227 29.1039 69.7757V37.9466C29.1039 34.1082 32.2778 30.9965 36.1929 30.9965C40.1081 30.9965 43.2819 34.1082 43.2819 37.9466V67.8396C43.2819 71.0346 45.253 73.9123 48.2696 75.1212L51.9824 76.6092C53.1891 77.0928 53.9775 78.2438 53.9775 79.5218V85.8395C53.9775 87.4882 52.7118 88.8726 51.0403 89.0523Z"
					fill="url(#paint1_linear_734_26)"
				/>
				<path
					d="M26.2154 51.764C26.2154 56.1086 22.623 59.6305 18.1916 59.6305C13.7602 59.6305 10.1679 56.1086 10.1679 51.764C10.1679 47.4195 13.7602 43.8975 18.1916 43.8975C22.623 43.8975 26.2154 47.4195 26.2154 51.764Z"
					fill="url(#paint2_linear_734_26)"
				/>
				<path
					d="M61.8408 51.764C61.8408 56.1086 58.2484 59.6305 53.817 59.6305C49.3856 59.6305 45.7933 56.1086 45.7933 51.764C45.7933 47.4195 49.3856 43.8975 53.817 43.8975C58.2484 43.8975 61.8408 47.4195 61.8408 51.764Z"
					fill="url(#paint3_linear_734_26)"
				/>
				<defs>
					<linearGradient
						id="paint0_linear_734_26"
						x1="107.852"
						y1="14.46"
						x2="107.852"
						y2="90.6"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#EDEDED" />
						<stop offset="1" stopColor="#EDEDED" />
					</linearGradient>
					<linearGradient
						id="paint1_linear_734_26"
						x1="107.852"
						y1="0"
						x2="107.852"
						y2="120"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#2269F8" />
						<stop offset="1" stopColor="#940686" />
					</linearGradient>
					<linearGradient
						id="paint2_linear_734_26"
						x1="107.852"
						y1="0"
						x2="107.852"
						y2="120"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#2269F8" />
						<stop offset="1" stopColor="#940686" />
					</linearGradient>
					<linearGradient
						id="paint3_linear_734_26"
						x1="107.852"
						y1="0"
						x2="107.852"
						y2="120"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#2269F8" />
						<stop offset="1" stopColor="#940686" />
					</linearGradient>
				</defs>
			</svg>
		</SvgIcon>
	);
};

export default LogoLight;
