import { IconButton, Typography, useMediaQuery } from "@mui/material";
import LogoLight from "../../assets/LogoLight";
import { Col, Row, StyledFooterLink } from "../../utils/styledComponents";

const Footer = () => {
  const tablet = useMediaQuery("(max-width:900px)");
  const mobile = useMediaQuery("(max-width:600px)");

  return (
    <Row
      sx={{
        backgroundColor: "background.dark",
        minHeight: "10rem",
        marginTop: "20vh",
        paddingLeft: tablet ? "5%" : "10%",
        paddingRight: tablet ? "5%" : "10%",
        paddingTop: tablet ? "1rem" : 0,
        paddingBottom: tablet ? "1rem" : 0,
      }}
      align="center"
      justify="space-between"
    >
      {!mobile && (
        <IconButton
          sx={{
            ":hover": {
              backgroundColor: "transparent",
            },
          }}
          onClick={() => window.open("https://www.tonk.finance/", "_self")}
        >
          <LogoLight
            width={mobile ? "6rem" : "12rem"}
            height={mobile ? "6rem" : "12rem"}
          />
        </IconButton>
      )}
      {mobile ? (
        <Row sx={{ flexWrap: "wrap" }} justify="space-evenly" gap={3}>
          <StyledFooterLink to="https://twitter.com/TONK_FINANCE">
            <Typography variant="body2" sx={{ color: "text.tertiary" }}>
              Twitter
            </Typography>
          </StyledFooterLink>
          <StyledFooterLink to="https://t.me/+sXi7kgYp2Cs3MDRk">
            <Typography variant="body2" sx={{ color: "text.tertiary" }}>
              Telegram
            </Typography>
          </StyledFooterLink>
          <StyledFooterLink to="https://www.tonk.finance/">
            <Typography variant="body2" sx={{ color: "text.tertiary" }}>
              Home page
            </Typography>
          </StyledFooterLink>
          <StyledFooterLink to="https://docs.tonk.finance/tonk-finance/">
            <Typography variant="body2" sx={{ color: "text.tertiary" }}>
              Documentation
            </Typography>
          </StyledFooterLink>
          <StyledFooterLink to="https://www.tonk.finance/terms-of-use">
            <Typography variant="body2" sx={{ color: "text.tertiary" }}>
              Terms of Use
            </Typography>
          </StyledFooterLink>
          <StyledFooterLink to="https://www.tonk.finance/privacy-policy">
            <Typography variant="body2" sx={{ color: "text.tertiary" }}>
              Privacy policy
            </Typography>
          </StyledFooterLink>
          <StyledFooterLink to="https://www.tonk.finance/disclaimer">
            <Typography variant="body2" sx={{ color: "text.tertiary" }}>
              Disclaimer
            </Typography>
          </StyledFooterLink>
        </Row>
      ) : (
        <Row justify="space-between" gap={5}>
          <Col gap={1}>
            <Typography variant="h3" sx={{ color: "text.tertiary" }}>
              Community
            </Typography>
            <Col gap={0.5}>
              <StyledFooterLink to="https://twitter.com/TONK_FINANCE">
                <Typography variant="body2" sx={{ color: "text.tertiary" }}>
                  Twitter
                </Typography>
              </StyledFooterLink>
              <StyledFooterLink to="https://t.me/+sXi7kgYp2Cs3MDRk">
                <Typography variant="body2" sx={{ color: "text.tertiary" }}>
                  Telegram
                </Typography>
              </StyledFooterLink>
            </Col>
          </Col>
          <Col gap={1}>
            <Typography variant="h3" sx={{ color: "text.tertiary" }}>
              Project
            </Typography>
            <Col gap={0.5}>
              <StyledFooterLink to="https://www.tonk.finance/">
                <Typography variant="body2" sx={{ color: "text.tertiary" }}>
                  Home page
                </Typography>
              </StyledFooterLink>
              <StyledFooterLink to="https://docs.tonk.finance/tonk-finance/">
                <Typography variant="body2" sx={{ color: "text.tertiary" }}>
                  Documentation
                </Typography>
              </StyledFooterLink>
              <StyledFooterLink to="https://www.tonk.finance/terms-of-use">
                <Typography variant="body2" sx={{ color: "text.tertiary" }}>
                  Terms of Use
                </Typography>
              </StyledFooterLink>
              <StyledFooterLink to="https://www.tonk.finance/privacy-policy">
                <Typography variant="body2" sx={{ color: "text.tertiary" }}>
                  Privacy policy
                </Typography>
              </StyledFooterLink>
              <StyledFooterLink to="https://www.tonk.finance/disclaimer">
                <Typography variant="body2" sx={{ color: "text.tertiary" }}>
                  Disclaimer
                </Typography>
              </StyledFooterLink>
            </Col>
          </Col>
        </Row>
      )}
    </Row>
  );
};

export default Footer;
