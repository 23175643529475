import { IconButton, Typography } from "@mui/material";
import { Col, Row } from "../../../utils/styledComponents";
import NumberRounded from "../../CommonComponents/NumberRounded";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { getTestnetJettons } from "../../../contracts/getTestnetJettons";
import { useTonConnectUI } from "@tonconnect/ui-react";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
const Hints = () => {
	const [tonConnectUI] = useTonConnectUI();
	const handleGetJettons = async () => {
		const transaction = getTestnetJettons();
		try {
			const result = await tonConnectUI.sendTransaction(transaction);
			return result;
		} catch (err) {
			const error = err as Error;
			throw new Error(error.message);
		}
	};
	return (
		<Col gap={2}>
			<Typography variant="h3">How to use?</Typography>
			<Row gap={1}>
				<NumberRounded text="1." />
				<Col>
					<Typography variant="body1">Obtain testnet TON</Typography>
					<Typography variant="caption" sx={{ color: "rgba(0, 0, 0, 0.6" }}>
						Use Testgiver TON bot to obtain testnet TON
					</Typography>
				</Col>
				<IconButton
					onClick={() => window.open("https://t.me/testgiver_ton_bot")}
				>
					<OpenInNewIcon style={{ color: "#940686" }} />
				</IconButton>
			</Row>
			<Row gap={1}>
				<NumberRounded text="2." />
				<Col>
					<Typography variant="body1">Obtain testnet jettons</Typography>
					<Typography variant="caption" sx={{ color: "rgba(0, 0, 0, 0.6" }}>
						Make sure to load your wallet with testnet jettons
					</Typography>
				</Col>
				<IconButton onClick={handleGetJettons}>
					<AccountBalanceWalletIcon style={{ color: "#940686" }} />
				</IconButton>
			</Row>
			<Row gap={1}>
				<NumberRounded text="3." />
				<Col>
					<Typography variant="body1">Obtain LP token</Typography>
					<Typography variant="caption" sx={{ color: "rgba(0, 0, 0, 0.6" }}>
						Deposit token pair directly on tonk and receive LP token in return
					</Typography>
				</Col>
			</Row>
			<Row gap={1}>
				<NumberRounded text="4." />
				<Col>
					<Typography variant="body1">Deposit LP token</Typography>
					<Typography variant="caption" sx={{ color: "rgba(0, 0, 0, 0.6" }}>
						Deposit LP token to the vault and obtain vault token that confirms
						your deposit
					</Typography>
				</Col>
			</Row>
			<Row gap={1}>
				<NumberRounded text="5." />
				<Col>
					<Typography variant="body1">Sit and enjoy</Typography>
					<Typography variant="caption" sx={{ color: "rgba(0, 0, 0, 0.6" }}>
						Your assets will now work hard to earn you profit
					</Typography>
				</Col>
			</Row>
		</Col>
	);
};

export default Hints;
