import {
  CircularProgress,
  Divider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Col, Row } from "../../../utils/styledComponents";
import LPPieChart from "./LPPieChart";
import { PoolDataContext } from "../../../providers/PoolDataProvider";
import React, { useContext } from "react";
import USDCIcon from "../../../assets/USDCicon.webp";
import usePrice from "../../../hooks/useGetPrice";
import useCalculateLP from "../../../hooks/useCalculateLP";
import { formatNumber } from "../../../utils/formatNumber";

const LPBreakdown = () => {
  const context = useContext(PoolDataContext);
  const { price, loadingPrice } = usePrice();
  const mobile = useMediaQuery("(max-width:700px)");
  const { jettons, lp } = useCalculateLP(
    context?.account?.balance,
    context?.poolData,
    price
  );
  if (loadingPrice) {
    return (
      <Row
        sx={{ width: "100%", height: "100%" }}
        align="center"
        justify="center"
      >
        <CircularProgress size={50} />
      </Row>
    );
  } else if (jettons.length === 0) {
    return (
      <Row
        sx={{ width: "100%", height: "100%" }}
        align="center"
        justify="center"
      >
        <Typography variant="body1">Could not fetch LP data</Typography>
      </Row>
    );
  } else if (mobile) {
    return (
      <Col sx={{ width: "100%", height: "100%" }} align="center">
        <Col sx={{ width: "100%", height: "18rem" }}>
          {context && context.poolData && <LPPieChart jettons={jettons} />}
        </Col>
        <Divider flexItem orientation="vertical" />
        <Col
          sx={{ width: "100%", height: "100%" }}
          justify="center"
          align="center"
        >
          <Row sx={{ width: "100%" }}>
            <Col
              sx={{
                width: "100%",
                // borderRadius: "25px",
                // padding: "1rem",
              }}
            >
              <Row
                justify="space-between"
                align="center"
                sx={{ padding: "1rem" }}
                // sx={{ width: "100%" }}
              >
                <div style={{ textAlign: "left" }}>
                  <Typography variant="body1" sx={{ fontWeight: "550" }}>
                    Asset
                  </Typography>
                </div>
                <div style={{ textAlign: "right" }}>
                  <Typography variant="body1" sx={{ fontWeight: "550" }}>
                    Value
                  </Typography>
                </div>
                <div style={{ textAlign: "right" }}>
                  <Typography variant="body1" sx={{ fontWeight: "550" }}>
                    Amount
                  </Typography>
                </div>
              </Row>
            </Col>
          </Row>
          {jettons.map((entry, index) => {
            return (
              <React.Fragment key={`jetton-${index}`}>
                <Divider flexItem />
                <Row sx={{ width: "100%" }}>
                  <Col
                    sx={{
                      width: "100%",
                      // borderRadius: "25px",
                      padding: "1rem",
                    }}
                  >
                    <Row
                      justify="space-between"
                      align="center"
                      sx={{ width: "100%" }}
                    >
                      <div style={{ textAlign: "left" }}>
                        <Row align="center">
                          <img src={entry.icon} height="30" />
                          {!mobile && (
                            <Typography variant="body1">
                              {entry.name}
                            </Typography>
                          )}
                        </Row>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <Typography variant="body1">
                          $ {formatNumber(entry.valuePerToken)}
                        </Typography>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <Typography variant="body1">
                          {formatNumber(entry.value)}
                        </Typography>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </React.Fragment>
            );
          })}
          <Row sx={{ width: "100%" }}>
            <Col
              sx={{
                width: "100%",
                boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                padding: "1rem",
                borderTop: "solid",
                borderTopWidth: "1px",
                borderTopColor: "text.tertiary",
              }}
            >
              <Row
                justify="space-between"
                align="center"
                sx={{ width: "100%" }}
              >
                <div style={{ textAlign: "left" }}>
                  <Row align="center">
                    <Row>
                      <img
                        height="25"
                        width="25"
                        src={jettons[0].icon}
                        style={{ zIndex: "5" }}
                        alt={`icon-${Math.random() * 2}`}
                      />
                      <img
                        height="25"
                        width="25"
                        style={{ marginLeft: "-15px" }}
                        src={jettons[1].icon}
                        alt={`icon-${Math.random() * 2}`}
                      />
                    </Row>
                    {!mobile && (
                      <Typography variant="body1">{lp.name}</Typography>
                    )}
                  </Row>
                </div>
                <div style={{ textAlign: "right" }}>
                  <Typography variant="body1">
                    $ {formatNumber(lp.valuePerToken)}
                  </Typography>
                </div>
                <div style={{ textAlign: "right" }}>
                  <Typography variant="body1">
                    {formatNumber(lp.value)}
                  </Typography>
                </div>
              </Row>
            </Col>
          </Row>
        </Col>
      </Col>
    );
  } else {
    return (
      <Row sx={{ width: "100%", height: "100%" }} align="center">
        <Col sx={{ flex: 0.5, height: "18rem" }}>
          {context && context.poolData && <LPPieChart jettons={jettons} />}
        </Col>
        <Divider flexItem orientation="vertical" />
        <Col
          sx={{ flex: 1, height: "100%", padding: "1rem" }}
          justify="center"
          align="center"
        >
          <Row sx={{ width: "100%" }}>
            <Col
              sx={{
                width: "100%",
                // borderRadius: "25px",
                padding: "1rem",
              }}
            >
              <Row
                justify="space-between"
                align="center"
                sx={{ width: "100%" }}
              >
                <div style={{ flex: 1, textAlign: "left" }}>
                  <Typography variant="body1" sx={{ fontWeight: "550" }}>
                    Asset
                  </Typography>
                </div>
                <div style={{ flex: 1, textAlign: "right" }}>
                  <Typography variant="body1" sx={{ fontWeight: "550" }}>
                    Value
                  </Typography>
                </div>
                <div style={{ flex: 1, textAlign: "right" }}>
                  <Typography variant="body1" sx={{ fontWeight: "550" }}>
                    Amount
                  </Typography>
                </div>
              </Row>
            </Col>
          </Row>
          {jettons.map((entry, index) => {
            return (
              <React.Fragment key={`jetton-${index}`}>
                <Divider flexItem />
                <Row sx={{ width: "100%" }}>
                  <Col
                    sx={{
                      width: "100%",
                      // borderRadius: "25px",
                      padding: "1rem",
                    }}
                  >
                    <Row
                      justify="space-between"
                      align="center"
                      sx={{ width: "100%" }}
                    >
                      <div style={{ flex: 1, textAlign: "left" }}>
                        <Row align="center" gap={0.5}>
                          <img src={USDCIcon} height="30" />
                          <Typography variant="body1">{entry.name}</Typography>
                        </Row>
                      </div>
                      <div style={{ flex: 1, textAlign: "right" }}>
                        <Typography variant="body1">
                          $ {formatNumber(entry.valuePerToken)}
                        </Typography>
                      </div>
                      <div style={{ flex: 1, textAlign: "right" }}>
                        <Typography variant="body1">
                          {formatNumber(entry.value)}
                        </Typography>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </React.Fragment>
            );
          })}
          <Row sx={{ width: "100%" }}>
            <Col
              sx={{
                width: "100%",
                boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                padding: "1rem",
                borderTop: "solid",
                borderTopWidth: "1px",
                borderTopColor: "text.tertiary",
              }}
            >
              <Row
                justify="space-between"
                align="center"
                sx={{ width: "100%" }}
              >
                <div style={{ flex: 1, textAlign: "left" }}>
                  <Row align="center" gap={0.5}>
                    <img src={USDCIcon} height="30" />
                    <Typography variant="body1">{lp.name}</Typography>
                  </Row>
                </div>
                <div style={{ flex: 1, textAlign: "right" }}>
                  <Typography variant="body1">
                    $ {formatNumber(lp.valuePerToken)}
                  </Typography>
                </div>
                <div style={{ flex: 1, textAlign: "right" }}>
                  <Typography variant="body1">
                    {formatNumber(lp.value)}
                  </Typography>
                </div>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
};

export default LPBreakdown;
