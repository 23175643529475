import {
  Box,
  CircularProgress,
  ListItemButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Col, Row } from "../../../../utils/styledComponents";
import SafetyScoreMeter from "./SafetyScoreMeter";
import { useContext, useEffect, useState } from "react";
import { PoolDataContext } from "../../../../providers/PoolDataProvider";
import MobileScoreMeter from "./MobileScoreMeter";
import SafetyScoreSnippets from "./SafetyScoreSnippets";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import SafetyScoreDescription from "./SafetyScoreDescription";
import { IFactors, poolsByScore } from "../../../../utils/scoreMocks";

const SafetyScoreWrapper = () => {
  const context = useContext(PoolDataContext);
  const mobile = useMediaQuery("(max-width:700px)");
  const [safetyScore, setSafetyScore] = useState<number>(0);
  const [factors, setFactors] = useState<IFactors>();
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (context && context.poolData) {
      const pool = poolsByScore.find((e) => e.id === context.poolData?.id);
      console.log(pool);
      if (pool) {
        setSafetyScore(100 + pool.total);
        setFactors(pool.factors);
      }
    }
  }, []);
  if (context?.loading) {
    return (
      <Row
        sx={{ width: "100%", height: "100%" }}
        align="center"
        justify="center"
      >
        <CircularProgress size={50} />
      </Row>
    );
  } else if (!context?.poolData) {
    return (
      <Row
        sx={{ width: "100%", height: "100%" }}
        align="center"
        justify="center"
      >
        <Typography variant="body1">Could not fetch LP data</Typography>
      </Row>
    );
  } else {
    return (
      <>
        <Row sx={{ width: "100%", height: "100%" }} align="center">
          {!mobile && (
            <Col sx={{ width: "50%", height: "20rem" }}>
              <SafetyScoreMeter value={safetyScore} />
            </Col>
          )}
          <Col
            sx={{
              width: mobile ? "100%" : "50%",
              height: "100%",
              paddingTop: mobile ? "1rem" : 0,
            }}
            align="center"
            justify="center"
          >
            <Typography variant="h3">Safety score</Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: "3rem!important",
                color:
                  safetyScore >= 75
                    ? "#33CC99"
                    : safetyScore < 75 && safetyScore > 30
                    ? "#FFB020"
                    : "#D32F2F",
              }}
            >
              {safetyScore}
            </Typography>
            <Box>{factors && <SafetyScoreSnippets factors={factors} />}</Box>
            {mobile && <MobileScoreMeter progress={safetyScore} />}
          </Col>
        </Row>
        <Box
          sx={{
            // backgroundColor: "#F0FCFF",
            width: "90%",
            borderRadius: "10px",
            margin: "1rem",
            boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ListItemButton
            onClick={handleExpandClick}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              borderRadius: "10px",
            }}
          >
            <Typography variant="h3" sx={{ fontWeight: "500" }}>
              What is the safety score?
            </Typography>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <SafetyScoreDescription expanded={expanded} />
        </Box>
      </>
    );
  }
};

export default SafetyScoreWrapper;
