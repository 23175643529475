import { SvgIcon } from "@mui/material";

const SingleCoinIcon = ({ width, height }) => {
	return (
		<SvgIcon sx={{ width: width, height: height }}>
			<svg
				width="25"
				height="25"
				viewBox="0 0 25 25"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M12.4999 5.20801C7.32388 5.20801 2.08325 6.81842 2.08325 9.89551C2.08325 12.9726 7.32388 14.583 12.4999 14.583C17.677 14.583 22.9166 12.9726 22.9166 9.89551C22.9166 6.81842 17.677 5.20801 12.4999 5.20801ZM7.29159 15.5601V18.6851C8.58013 18.9966 10.0051 19.1872 11.4583 19.2486V16.1236C10.0546 16.069 8.65935 15.8802 7.29159 15.5601ZM13.5416 16.1226V19.2476C14.9454 19.194 16.3407 19.0053 17.7083 18.6841V15.5591C16.3407 15.8803 14.9454 16.069 13.5416 16.1226ZM19.7916 14.8924V18.0174C21.6676 17.2309 22.9166 16.083 22.9166 14.583V11.458C22.9166 12.958 21.6676 14.1059 19.7916 14.8924ZM5.20825 18.0174V14.8924C3.33325 14.1059 2.08325 12.957 2.08325 11.458V14.583C2.08325 16.082 3.33325 17.2309 5.20825 18.0174Z"
					fill="#2269F8"
				/>
			</svg>
		</SvgIcon>
	);
};

export default SingleCoinIcon;
