import {
	Box,
	CircularProgress,
	Grid,
	IconButton,
	InputBase,
	Typography,
} from "@mui/material";
import { PoolTableProps } from "../../types/interface";
import { Col, Row } from "../../utils/styledComponents";
import IconsWrapper from "../CommonComponents/IconsWrapper";
import useNameFilter from "../../hooks/useNameFilter";
import { useContext, useEffect, useState } from "react";
import { JettonWalletContext } from "../../providers/JettonWalletProvider";
import usePoolsFilter from "../../hooks/usePoolsFilter";
import useCategoryFilter from "../../hooks/useCategoryFilter";
import CategoryTabs from "./CategoryTabs";
import SearchIcon from "@mui/icons-material/Search";
import HelpIcon from "@mui/icons-material/Help";
import { useNavigate } from "react-router-dom";
import MobileCategorySelector from "./MobileCategorySelector";
import { Address } from "ton-core";
const PoolsList: React.FC<PoolTableProps> = ({ rows, loading }) => {
	const navigate = useNavigate();
	const { poolName, filter, data } = useNameFilter(rows);
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const [userPools, setUserPools] = useState<number>(0);
	const walletContext = useContext(JettonWalletContext);
	const { poolsFilter, handleFilterPools, filteredArrPools } = usePoolsFilter(
		data,
		walletContext ? walletContext : null
	);
	const {
		categoryFilter,
		handleFilterCategory,
		filteredArr,
		handleMobileFilterCategory,
	} = useCategoryFilter(filteredArrPools, isMobile);
	useEffect(() => {
		if (walletContext) {
			const balance =
				walletContext.jettonWallets?.filter(
					(entry) => parseFloat(entry.balance) > 0
				) || [];
			console.log(balance);
			console.log(rows);
			const myPools = rows?.filter((row) =>
				balance.some(
					(e) =>
						e.jetton === Address.parse(row.address).toRawString().toUpperCase()
				)
			);
			console.log(myPools);
			if (myPools) {
				console.log(myPools);
				setUserPools(myPools.length);
			}
		}
	}, [walletContext, rows]);
	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 900);
		};

		handleResize();

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);
	return (
		<Grid container columnSpacing={4} spacing={4}>
			<Grid item xs={12} sx={{ alignSelf: "center" }}>
				<Row
					// justify="space-between"
					sx={{
						flexWrap: "wrap",
						backgroundColor: "background.paper",
						// height: "10rem",
						justifyContent: isMobile ? "center" : "space-between",
						boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
						borderRadius: "25px",
						padding: "1rem",

						gap: 2,
					}}
				>
					<CategoryTabs
						category={poolsFilter}
						handleChange={handleFilterPools}
						tabs={[
							{ id: 1, label: "All vaults", value: "all" },
							{
								id: 2,
								label: `My vaults (${userPools})`,
								value: "my",
							},
						]}
					/>

					{isMobile ? (
						<MobileCategorySelector
							tab={categoryFilter}
							handleChange={handleMobileFilterCategory}
							tabs={[
								{ id: 1, label: "All categories", value: "all" },
								{ id: 2, label: "Featured", value: "featured" },
								{ id: 3, label: "Stablecoins", value: "stablecoins" },
								// { id: 4, label: "Highest APY", value:'' },
								{ id: 5, label: "TON pairs only", value: "ton_pair" },
							]}
						/>
					) : (
						<CategoryTabs
							category={categoryFilter}
							handleChange={handleFilterCategory}
							tabs={[
								{ id: 1, label: "All categories", value: "all" },
								{ id: 2, label: "Featured", value: "featured" },
								{ id: 3, label: "Stablecoins", value: "stablecoins" },
								// { id: 4, label: "Highest APY", value:'' },
								{ id: 5, label: "TON pairs only", value: "ton_pair" },
							]}
						/>
					)}
					<Box sx={{ width: isMobile ? "100%" : "auto" }}>
						<InputBase
							placeholder="Search..."
							onChange={filter}
							inputProps={{ "aria-label": "search" }}
							endAdornment={
								<IconButton
									type="button"
									sx={{ p: "10px" }}
									aria-label="search"
								>
									<SearchIcon />
								</IconButton>
							}
							sx={{
								backgroundColor: "background.paper",
								border: "solid",
								borderColor: "rgba(0,0,0,0.05)",
								borderRadius: "10px",
								// display: "flex",
								width: isMobile ? "100%" : "auto",
								paddingLeft: "0.5rem",
								paddingRight: "0.5rem",
								"&.Mui-focused": {
									borderColor: "primary.main",
								},
							}}
						/>
					</Box>
				</Row>
			</Grid>
			{loading ? (
				<Grid item xs={12}>
					<Col
						justify="space-evenly"
						align="center"
						sx={{
							backgroundColor: "background.paper",
							height: "20rem",
							boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
							borderRadius: "25px",
							padding: "1rem",
						}}
					>
						<CircularProgress size={100} />
					</Col>
				</Grid>
			) : filteredArr && filteredArr.length > 0 ? (
				filteredArr.map((entry, index) => {
					return (
						<Grid xs={12} md={6} lg={4} item key={`${entry.id}-${index}`}>
							<Col
								onClick={() =>
									navigate(`/${entry.id}`, {
										state: { payload: entry },
									})
								}
								justify="space-evenly"
								sx={{
									backgroundColor: "background.paper",
									height: "10rem",
									boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
									borderRadius: "25px",
									padding: "1rem",
									cursor: "pointer",
									"&:hover": {
										backgroundColor: "white",
									},
								}}
							>
								<Box sx={{ marginTop: "-3rem" }}>
									<IconsWrapper
										icon1={entry.token1.icon}
										icon2={entry.token2.icon}
									/>
								</Box>

								<Typography variant="h2" sx={{ fontWeight: "550" }}>
									{entry.token1.symbol}-{entry.token2.symbol}
								</Typography>
								<Row justify="space-between">
									<Col
										sx={{
											boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
											margin: "0.2rem",
											padding: "0.5rem",
											flex: 0.5,
											border: "solid",
											borderRadius: "10px",
											borderColor: "transparent",
										}}
									>
										<Typography variant="h3" sx={{ opacity: 0.7 }}>
											TVL
										</Typography>
										<Typography variant="h2" sx={{ fontWeight: "500" }}>
											$ {entry.tvl}
										</Typography>
									</Col>
									<Col
										sx={{
											boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
											margin: "0.2rem",
											padding: "0.5rem",
											flex: 0.5,
											border: "solid",
											borderRadius: "10px",
											borderColor: "#33CC99",
										}}
									>
										<Typography variant="h3" sx={{ opacity: 0.7 }}>
											APY
										</Typography>
										<Typography
											variant="h2"
											sx={{ color: "#33CC99", fontWeight: "500" }}
										>
											{entry.apy
												? parseInt(entry.apy) === -1
													? "--"
													: parseFloat(entry.apy) > 10000
													? ">10000"
													: `${(
															Math.floor(parseFloat(entry.apy) * 100) / 100
													  ).toLocaleString()}`
												: "--"}{" "}
											%
										</Typography>
									</Col>
								</Row>
							</Col>
						</Grid>
					);
				})
			) : (
				<Grid item xs={12}>
					<Col
						justify="space-evenly"
						align="center"
						sx={{
							backgroundColor: "background.paper",
							height: "10rem",
							boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
							borderRadius: "25px",
							padding: "1rem",
						}}
					>
						<HelpIcon sx={{ fontSize: "3rem", opacity: 0.5 }} />
						<Typography variant="h2" sx={{ fontWeight: "600", opacity: 0.5 }}>
							No farm found
						</Typography>
					</Col>
				</Grid>
			)}
		</Grid>
	);
};

export default PoolsList;
