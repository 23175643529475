import { Typography } from "@mui/material";
import { Col, Row } from "../../../utils/styledComponents";
import CustomTooltip from "../../CommonComponents/CustomTooltip";

const Fees = () => {
  return (
    <Col>
      <Row justify="space-between" align="center">
        <Row gap={1} align="center">
          <Typography variant="caption">Deposit fee</Typography>
          <CustomTooltip text="Charged by underlying provider, not tonk" />
        </Row>
        <Typography variant="caption">0%</Typography>
      </Row>
      <Row justify="space-between" align="center">
        <Row gap={1} align="center">
          <Typography variant="caption">Withdraw fee</Typography>
          <CustomTooltip text="Charged by underlying provider, not tonk" />
        </Row>
        <Typography variant="caption">0%</Typography>
      </Row>
      <Row justify="space-between" align="center">
        <Row gap={1} align="center">
          <Typography variant="caption">Performance fee</Typography>
          <CustomTooltip text="Charged by tonk" />
        </Row>
        <Typography variant="caption">0.1%</Typography>
      </Row>
    </Col>
  );
};

export default Fees;
