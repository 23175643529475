import { createTheme } from "@mui/material/styles";

const theme = createTheme({
	palette: {
		primary: {
			main: "#2269F8",
			transparent: "#c1d3f7",
		},
		secondary: {
			main: "#940686",
			transparent: "#debada",
		},
		tertiary: {
			main: "#22C5F8",
			transparent: "#c1eaf7",
		},
		error: {
			main: "#D32F2F",
		},
		background: {
			default: "#F0FCFF",
			paper: "#F7F7F7",
			dark: "#252525",
		},
		text: {
			primary: "#252525",
			tertiary: "#EDEDED",
		},
	},
	typography: {
		color: "#252525",
		fontFamily: "Fredoka, sans-serif",
	},
});
theme.typography.h1 = {
	fontSize: "2rem",
	color: "#252525",
	fontWeight: "450",
	fontFamily: "Fredoka, sans-serif",
	"@media (max-width:600px)": {
		fontSize: "1.5rem",
	},
};
theme.typography.h2 = {
	fontSize: "1.5rem",
	color: "#252525",
	fontFamily: "Fredoka, sans-serif",
	"@media (max-width:600px)": {
		fontSize: "1rem",
	},
};
theme.typography.h3 = {
	fontSize: "1rem",
	color: "#252525",
	fontWeight: "450",
	fontFamily: "Fredoka, sans-serif",
	"@media (max-width:600px)": {
		fontSize: "1rem",
	},
};
theme.typography.h4 = {
	fontSize: "1rem",
	color: "#252525",
	fontFamily: "Fredoka, sans-serif",
	"@media (max-width:600px)": {
		fontSize: "1rem",
	},
};
theme.typography.body1 = {
	fontSize: "1rem",
	color: "#252525",
	fontFamily: "Fredoka, sans-serif",
	"@media (max-width:600px)": {
		fontSize: "1rem",
	},
};
// theme.typography.body2 = {
// 	fontSize: "3rem",
// 	color: "#2269F8",
// 	fontFamily: "Fredoka, sans-serif",
// 	"@media (max-width:600px)": {
// 		fontSize: "1.5rem",
// 	},
// };

export default theme;
