import {
	EXCHANGE_OP_CODES,
	Exchange,
	SafeAddress,
	Vault,
} from "@tonk-finance/sdk";
import { toNano } from "ton-core";
import { generateUniqueBigInt, generateUniqueInteger } from "./utils";

export const depositLPBody = (
	amount: number,
	destination: SafeAddress,
	responseDestination: SafeAddress
) => {
	const body = Vault.createDepositBody({
		queryId: generateUniqueInteger(),
		amount: toNano(amount),
		destination: destination, //user address
		response_destination: responseDestination, //vault master address
		custom_payload: null,
		forward_ton_amount: toNano(0.1),
		forward_payload: { from: responseDestination }, //vault master address
	});
	console.log("depositLPBody: ", body);
	return body;
};

export const depositJettonBody0 = (
	amount: number,
	contractAddress: SafeAddress,
	userAddress: SafeAddress
) => {
	const op = EXCHANGE_OP_CODES.PROVIDE_TOKEN;
	console.log(op);
	const body = Exchange.createProvideJettonBody({
		queryId: generateUniqueInteger(),
		amount: toNano(amount),
		destination: contractAddress,
		response_destination: userAddress,
		custom_payload: null,
		forward_ton_amount: toNano(0.3),
		forward_payload: {
			queryId: generateUniqueBigInt(),
			amount0: toNano(amount),
			amount1: toNano(0),
			minLpOut: toNano(amount),
		},
	});
	console.log("depositJettonBody: ", body);
	return body;
};
export const depositJettonBody1 = (
	amount: number,
	contractAddress: SafeAddress,
	userAddress: SafeAddress
) => {
	const op = EXCHANGE_OP_CODES.PROVIDE_TOKEN;
	console.log(op);
	const body = Exchange.createProvideJettonBody({
		queryId: generateUniqueInteger(),
		amount: toNano(amount),
		destination: contractAddress,
		response_destination: userAddress,
		custom_payload: null,
		forward_ton_amount: toNano(0.3),
		forward_payload: {
			queryId: generateUniqueBigInt(),
			amount1: toNano(amount),
			amount0: toNano(0),
			minLpOut: toNano(amount),
		},
	});
	console.log("depositJettonBody: ", body);
	return body;
};

export const withdrawBody = (
	amount: number,
	userAddress: SafeAddress,
	contractAddress: SafeAddress
) => {
	const body = Vault.createWithdrawBody({
		queryId: generateUniqueBigInt(),
		amount: toNano(amount),
		custom_payload: null,
		response_destination: userAddress,
	});
	return body;
};
export const withdrawJettonBody = (
	amount: number,
	userAddress: SafeAddress,
	contractAddress: SafeAddress
) => {
	const body = Exchange.createWithdrawJettonsBody({
		queryId: generateUniqueInteger(),
		amount: toNano(amount),
		destination: contractAddress,
		response_destination: userAddress,
		custom_payload: null,
		forward_ton_amount: toNano("0.5"),
		forward_payload: {
			minAmount: toNano(amount),
		},
	});
	return body;
};
