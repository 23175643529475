import { useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import { useContext, useState } from "react";
import { sendDepositPair } from "../../../contracts/transactionConstructors";
import { IDepositPair } from "../../../types/interface";
import ButtonRectangular from "../../CommonComponents/ButtonRectangular";
import { UserWalletsContext } from "../../../page/PoolPage";
import { useAnalyticsEventTracker } from "../../../hooks/useAnalyticsEventTracker";
import { AnalyticsContext } from "../../../providers/AnalyticsProvider";
import { logEvent } from "@firebase/analytics";

// destination - master contract address (the vault)
// responseDestination - user address
// userJettonWalletContract - address of wallet for particular jetton owned by user

const DepositPairButton: React.FC<IDepositPair> = ({
	lpToken,
	token1,
	token2,
	userAddress,
	amount0,
	amount1,
	jettonsAvailable,
	handleModal,
	// handleTransactionConcluded,
}) => {
	//responseDestination = user address
	const [loading, setLoading] = useState(false);
	const [tonConnectUI] = useTonConnectUI();
	const walletContext = useContext(UserWalletsContext);
	const wallet = useTonWallet();
	const gaEvent = useAnalyticsEventTracker();
	const analyticsContext = useContext(AnalyticsContext);
	const handleChainMessages = async () => {
		setLoading(true);
		// handleTransactionConcluded(false, "", 0);
		if (!walletContext) {
			console.error("Client context is not available");
			setLoading(false);
			return;
		}
		try {
			const transaction = await sendDepositPair(
				userAddress,
				lpToken,
				token1,
				token2,
				walletContext?.userWallets.token1?.address as string,
				walletContext?.userWallets.token2?.address as string,
				amount0,
				amount1
			);
			if (transaction) {
				console.log(transaction);
				const result = await tonConnectUI.sendTransaction(transaction);
				if (result) {
					console.log(result);
					handleModal(result.boc);
					if (analyticsContext) {
						logEvent(analyticsContext.analytics, "contract", {
							contract: "PAIR_TO_LP",
							status: "Success",
							errMessage: "none",
						});
					}
				}

				return result;
			}
		} catch (err) {
			const error = err as Error;
			throw new Error(error.message);
		} finally {
			setLoading(false);
		}
	};
	const onClick = async () => {
		handleChainMessages();
	};
	const disabledTotal =
		jettonsAvailable.token1 < amount0 ||
		jettonsAvailable.token2 < amount1 ||
		loading;
	const notEnough =
		jettonsAvailable.token1 < amount0 || jettonsAvailable.token2 < amount1;
	return (
		<ButtonRectangular
			onClick={onClick}
			disabled={disabledTotal}
			text={
				loading ? "Pending..." : notEnough ? "Not enough tokens" : "Deposit"
			}
		></ButtonRectangular>
	);
};

export default DepositPairButton;
