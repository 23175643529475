import { Exchange, SafeAddress } from "@tonk-finance/sdk";
import { generateUniqueBigInt } from "./utils";
import { depositJettonBody0, depositJettonBody1 } from "./bodyConstructors";
export const createDeploy = async (
	targetTokenAddress: SafeAddress,
	addressInputToken0: SafeAddress,
	addressInputToken1: SafeAddress,
	userAddress: SafeAddress,
	queryId: bigint
) => {
	try {
		const exchange = await Exchange.createDeploy(
			{
				queryId: queryId,
				userAddress: userAddress, //USER WALLET, E.G. TONKEEPER
				targetTokenAddress: targetTokenAddress, //LP TOKEN MASTER ADDRESS
				addressInputToken0: addressInputToken0, //MASTER ADDRESS TOKEN0
				addressInputToken1: addressInputToken1, //MASTER ADDRESS TOKEN1
			},
			true
		);
		return exchange;
	} catch (err) {
		const error = err as Error;
		throw new Error(error.message);
	}
};

export const depositPair = async (
	targetTokenAddress: SafeAddress,
	addressInputToken0: SafeAddress,
	addressInputToken1: SafeAddress,
	userAddress: SafeAddress,
	amount0: number,
	amount1: number
) => {
	const queryId = generateUniqueBigInt();

	try {
		const contract = await createDeploy(
			targetTokenAddress,
			addressInputToken0,
			addressInputToken1,
			userAddress,
			queryId
		);

		if (contract) {
			const contractAddress = contract.address;
			const jettonBody0 = depositJettonBody0(
				amount0,
				contractAddress,
				userAddress
			);
			const jettonBody1 = depositJettonBody1(
				amount1,
				contractAddress,
				userAddress
			);
			return {
				exchange: contractAddress,
				contractInit: contract.stateInit,
				createDeploy: contract.message,
				createProviderJettonBody0: jettonBody0,
				createProviderJettonBody1: jettonBody1,
			};
		}
	} catch (err) {
		const error = err as Error;
		throw new Error(error.message);
	}
};
