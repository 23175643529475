import { useContext, useEffect, useState } from "react";
import { Col, Row } from "../../../utils/styledComponents";
import { Typography, useMediaQuery } from "@mui/material";
import { PoolDataContext } from "../../../providers/PoolDataProvider";
import { JettonWalletContext } from "../../../providers/JettonWalletProvider";
import { Address, fromNano } from "ton-core";
import GetVaultSupply from "../../../api/GetVaultSupply";
import CustomTooltip from "../../CommonComponents/CustomTooltip";

const PoolDetails = () => {
  const tablet = useMediaQuery("(max-width:900px)");
  const context = useContext(PoolDataContext);
  const walletContext = useContext(JettonWalletContext);
  const [userDeposit, setUserDeposit] = useState<number>();
  const [vaultTokenValue, setVaultTokenValue] = useState<number>();
  const getVaultSupply = async () => {
    if (context && context.poolData) {
      const address = context.poolData.address;
      const result = await GetVaultSupply(address);
      const tvl = context.poolData.tvl;
      if (result && tvl) {
        console.log(address);
        console.log("supply", fromNano(result));
        console.log("tvl", tvl);
        const supply = fromNano(result);

        const value = tvl / parseFloat(supply);
        console.log(value);
        setVaultTokenValue(value);
        return value;
      }
    }
  };
  useEffect(() => {
    getVaultSupply();
  }, []);
  const handleUserDeposit = async () => {
    if (
      walletContext &&
      walletContext.jettonWallets &&
      context &&
      context.poolData
    ) {
      const jettonAddress = context.poolData.address;
      const walletData = walletContext.jettonWallets.find(
        (entry) =>
          entry.jetton ===
          Address.parse(jettonAddress).toRawString().toUpperCase()
      );

      if (walletData && vaultTokenValue) {
        console.log("fromnano", fromNano(walletData.balance));
        const val =
          Math.round(
            parseFloat(fromNano(walletData.balance)) * vaultTokenValue * 100
          ) / 100;
        console.log(val);
        setUserDeposit(val);
      } else {
        return setUserDeposit(0);
      }
    } else {
      return setUserDeposit(0);
    }
  };
  useEffect(() => {
    handleUserDeposit();
  }, [vaultTokenValue]);
  return (
    <Row gap={5} sx={{ flexWrap: "wrap" }}>
      <Col
        sx={{
          backgroundColor: "background.paper",
          padding: "1rem",
          borderRadius: "15px",
          alignItems: "center",
          boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
          gap: 1,
          flex: 1,
          flexWrap: "wrap",
          border: "solid",
          borderColor: "transparent",
          // width: "100%",
        }}
        justify="space-evenly"
      >
        <Row gap={1} align="center">
          <Typography variant={tablet ? "caption" : "body1"}>TVL</Typography>
          <CustomTooltip text="TVL (Total Value Locked) indicates the total amount of assets currently deposited in a vault." />
        </Row>
        <Typography variant="h2" sx={{ fontWeight: "regular" }}>{`$ ${
          context?.poolData?.tvl ? context?.poolData?.tvl : 0
        }`}</Typography>
      </Col>
      <Col
        sx={{
          backgroundColor: "background.paper",
          padding: "1rem",
          borderRadius: "15px",
          alignItems: "center",
          boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
          gap: 1,
          flex: 1,
          flexWrap: "wrap",
          border: "solid",
          borderColor: "#33CC99",
          // width: "100%",
        }}
        justify="space-evenly"
      >
        {" "}
        <Row gap={1} align="center">
          <Typography variant={tablet ? "caption" : "body1"}>APY</Typography>
          <CustomTooltip text="APY (Annual Percentage Yield) represents the real rate of return on your savings or investment over a year, taking into account the effect of compounding interest. The more frequently interest is compounded, the higher the APY will be." />
        </Row>
        <Typography variant="h2" sx={{ fontWeight: "regular" }}>{`${
          context?.poolData?.apy
            ? parseInt(context.poolData.apy) === -1
              ? "--"
              : parseFloat(context.poolData.apy) > 10000
              ? ">10000"
              : `${(
                  Math.floor(parseFloat(context.poolData.apy) * 100) / 100
                ).toLocaleString()}`
            : 0
        } %`}</Typography>
      </Col>
      <Col
        sx={{
          backgroundColor: "background.paper",
          padding: "1rem",
          borderRadius: "15px",
          alignItems: "center",
          boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
          gap: 1,
          flex: 1,
          flexWrap: "wrap",
          border: "solid",
          borderColor: "secondary.main",
          // width: "100%",
        }}
        justify="space-evenly"
      >
        {" "}
        <Typography variant={tablet ? "caption" : "body1"}>
          Your deposit
        </Typography>
        <Typography variant="h2" sx={{ fontWeight: "regular" }}>
          {context && context.poolData && userDeposit
            ? `$ ${userDeposit}`
            : `$ 0`}
        </Typography>
      </Col>
    </Row>
    // <Row
    // 	sx={{
    // 		backgroundColor: "background.paper",
    // 		padding: "1rem",
    // 		borderRadius: "25px",
    // 		alignItems: "center",
    // 		boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
    // 		gap: 5,
    // 		flexWrap: "wrap",
    // 		// width: "100%",
    // 	}}
    // 	justify="space-evenly"
    // >
    // 	<Col gap={1}>
    // 		<Row gap={1} align="center">
    // 			<Typography variant={tablet ? "caption" : "body1"}>TVL</Typography>
    // 			<CustomTooltip text="TVL (Total Value Locked) indicates the total amount of assets currently deposited in a vault." />
    // 		</Row>
    // 		<Typography variant="h2" sx={{ fontWeight: "regular" }}>{`$ ${
    // 			context?.poolData?.tvl ? context?.poolData?.tvl : 0
    // 		}`}</Typography>
    // 	</Col>
    // 	<Col gap={1}>
    // 		<Row gap={1} align="center">
    // 			<Typography variant={tablet ? "caption" : "body1"}>APY</Typography>
    // 			<CustomTooltip text="APY (Annual Percentage Yield) represents the real rate of return on your savings or investment over a year, taking into account the effect of compounding interest. The more frequently interest is compounded, the higher the APY will be." />
    // 		</Row>
    // 		<Typography variant="h2" sx={{ fontWeight: "regular" }}>{`${
    // 			context?.poolData?.apy
    // 				? parseInt(context.poolData.apy) === -1
    // 					? "--"
    // 					: parseFloat(context.poolData.apy) > 1000
    // 					? ">1000"
    // 					: `${(
    // 							Math.floor(parseFloat(context.poolData.apy) * 100) / 100
    // 					  ).toLocaleString()}`
    // 				: 0
    // 		} %`}</Typography>
    // 	</Col>
    // 	<Col gap={1}>
    // 		<Row gap={1} align="center">
    // 			<Typography variant={tablet ? "caption" : "body1"}>APR</Typography>
    // 			<CustomTooltip text="APR (Annual Percentage Rate) represents the annual rate of return from an investment without factoring in compounding effects. It shows the straightforward yearly earnings on your crypto assets, helping you compare the performance of different DeFi strategies and platforms." />
    // 		</Row>
    // 		<Typography variant="h2" sx={{ fontWeight: "regular" }}>{`${
    // 			context?.poolData?.apr
    // 				? `${(
    // 						Math.floor(parseFloat(context.poolData.apr) * 100) / 100
    // 				  ).toLocaleString()}`
    // 				: 0
    // 		} %`}</Typography>
    // 	</Col>
    // 	<Col gap={1}>
    // 		<Typography variant={tablet ? "caption" : "body1"}>Daily</Typography>
    // 		<Typography variant="h2" sx={{ fontWeight: "regular" }}>{`${
    // 			context?.poolData?.dailyApr ? context?.poolData?.dailyApr : 0
    // 		} %`}</Typography>
    // 	</Col>
    // 	{!tablet && <Divider orientation="vertical" flexItem />}
    // 	<Col gap={1}>
    // 		<Typography variant={tablet ? "caption" : "body1"}>
    // 			Your deposit
    // 		</Typography>
    // 		<Typography variant="h2" sx={{ fontWeight: "regular" }}>
    // 			{context && context.poolData && userDeposit
    // 				? `$ ${userDeposit}`
    // 				: `$ 0`}
    // 		</Typography>
    // 	</Col>
    // </Row>
  );
};

export default PoolDetails;
