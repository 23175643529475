import { Button } from "@mui/material";
import { IButtonProps } from "../../types/interface";

const ButtonRectangular: React.FC<IButtonProps> = ({
  text,
  onClick,
  disabled,
  variant,
}) => {
  return (
    <Button
      sx={{
        borderRadius: "10px",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        backgroundColor:
          variant === "outlined" ? "transparent" : "primary.main",
        textTransform: "none",
        width: "100%",
        // maxWidth: "10rem",
        alignSelf: "center",
      }}
      variant={variant === "outlined" ? "outlined" : "contained"}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default ButtonRectangular;
