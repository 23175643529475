import { Divider, Typography } from "@mui/material";
import { Col } from "../../../utils/styledComponents";
import { useContext } from "react";
import { PoolDataContext } from "../../../providers/PoolDataProvider";

const VaultDescription = () => {
  const context = useContext(PoolDataContext);
  return (
    <Col
      justify="flex-start"
      align="flex-start"
      sx={{ height: "100%", width: "100%" }}
    >
      <Col sx={{ padding: "2rem" }}>
        <Typography variant="h1" sx={{ textAlign: "left" }}>
          Strategy
        </Typography>
        <Divider
          flexItem
          sx={{
            height: "2px",
            backgroundColor: "primary.main",
            borderRadius: "25px",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        />
        <Typography variant="body1" sx={{ textAlign: "left" }}>
          {`The vault aggregates ${context?.poolData?.token1.symbol}-${context?.poolData?.token2.symbol} LP tokens from users and invests them
				in a Tonk testnet Vault, aiming to accumulate the farm's governance
				tokens as rewards. These governance tokens are then exchanged for ${context?.poolData?.token1.symbol}
				and ${context?.poolData?.token2.symbol} to purchase additional LP tokens of the same pair. This
				process, known as compounding, ensures that newly acquired ${context?.poolData?.token1.symbol}-${context?.poolData?.token2.symbol}
				LP tokens are reinvested into the farm, setting the stage for future
				rewards. The costs associated with these transactions are distributed
				among all vault participants, making the investment process more
				efficient for individuals.`}
        </Typography>
      </Col>
    </Col>
  );
};

export default VaultDescription;
