import { SvgIcon } from "@mui/material";

const LogoMain = ({ width, height }) => {
	return (
		<SvgIcon sx={{ width: width, height: height }}>
			<svg viewBox="0 0 108 60" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M50.006 22.7823C55.5622 25.9901 55.5622 34.0099 50.006 37.2177L12.5015 58.871C6.94528 62.0789 0 58.069 0 51.6532V8.34677C0 1.93099 6.94528 -2.07886 12.5015 1.12902L50.006 22.7823Z"
					fill="url(#paint0_linear_682_327)"
				/>
				<path
					d="M69.7167 30.4584L68.3624 30.375V33.2504C68.3624 33.6254 68.4179 33.8963 68.5291 34.063C68.6541 34.2297 68.8763 34.313 69.1958 34.313C69.5292 34.313 69.7792 34.3269 69.9459 34.3547C70.1265 34.3686 70.314 34.4311 70.5085 34.5422C70.8279 34.7228 70.9877 35.1881 70.9877 35.9382C70.9877 36.8133 70.7932 37.3481 70.4043 37.5426C70.1959 37.6398 70.0084 37.7023 69.8417 37.7301C69.6889 37.744 69.4597 37.7509 69.1541 37.7509C67.7095 37.7509 66.6122 37.4245 65.8621 36.7716C65.112 36.1188 64.7369 35.0145 64.7369 33.4587V30.375C64.473 30.4167 64.1883 30.4375 63.8827 30.4375C63.5771 30.4375 63.3201 30.3195 63.1117 30.0833C62.9173 29.8472 62.82 29.4444 62.82 28.8749C62.82 28.3053 62.8548 27.8956 62.9242 27.6455C62.9937 27.3816 63.0909 27.1941 63.2159 27.083C63.4382 26.9024 63.716 26.8121 64.0493 26.8121L64.7369 26.8955V24.7494C64.7369 24.4299 64.7439 24.1937 64.7578 24.0409C64.7855 23.8882 64.855 23.7215 64.9661 23.5409C65.1467 23.1936 65.6815 23.02 66.5705 23.02C67.515 23.02 68.0776 23.277 68.2582 23.7909C68.3276 24.0132 68.3624 24.3674 68.3624 24.8535V26.8955C68.9735 26.8399 69.4319 26.8121 69.7375 26.8121C70.057 26.8121 70.2932 26.826 70.4459 26.8538C70.6126 26.8677 70.8002 26.9302 71.0085 27.0413C71.3697 27.2219 71.5502 27.7567 71.5502 28.6457C71.5502 29.5208 71.3627 30.0556 70.9877 30.25C70.7793 30.3473 70.5918 30.4098 70.4251 30.4375C70.2723 30.4514 70.0362 30.4584 69.7167 30.4584Z"
					fill="#252525"
				/>
				<path
					d="M72.3882 32.2711C72.3882 30.6042 72.9716 29.2291 74.1385 28.1456C75.3053 27.0621 76.6387 26.5204 78.1389 26.5204C79.6391 26.5204 80.9657 27.0621 82.1186 28.1456C83.2715 29.2152 83.8479 30.5834 83.8479 32.2503C83.8479 33.4032 83.5493 34.4311 82.952 35.334C82.3547 36.223 81.6255 36.8828 80.7642 37.3134C79.9169 37.7301 79.0349 37.9384 78.1181 37.9384C77.2013 37.9384 76.3123 37.7162 75.4511 37.2717C74.5899 36.8133 73.8606 36.1466 73.2633 35.2715C72.6799 34.3825 72.3882 33.3823 72.3882 32.2711ZM76.7429 33.7713C77.1874 34.1047 77.6389 34.2713 78.0973 34.2713C78.5556 34.2713 79.014 34.0977 79.4724 33.7505C79.9308 33.4032 80.16 32.8892 80.16 32.2086C80.16 31.528 79.9447 31.021 79.5141 30.6876C79.0835 30.3542 78.6181 30.1875 78.1181 30.1875C77.618 30.1875 77.1527 30.3612 76.7221 30.7084C76.2915 31.0557 76.0762 31.5696 76.0762 32.2503C76.0762 32.917 76.2984 33.424 76.7429 33.7713Z"
					fill="#252525"
				/>
				<path
					d="M91.299 26.7704C92.6881 26.7704 93.841 27.3052 94.7578 28.3748C95.6884 29.4305 96.1538 30.7223 96.1538 32.2503V35.9174C96.1538 36.2369 96.1399 36.4799 96.1121 36.6466C96.0982 36.7994 96.0427 36.9731 95.9454 37.1675C95.751 37.5426 95.2092 37.7301 94.3202 37.7301C93.334 37.7301 92.7645 37.4731 92.6117 36.9592C92.5284 36.723 92.4867 36.3688 92.4867 35.8965V32.2294C92.4867 31.6599 92.32 31.2154 91.9866 30.8959C91.6671 30.5765 91.2296 30.4167 90.674 30.4167C90.1322 30.4167 89.6877 30.5834 89.3405 30.9168C89.0071 31.2501 88.8404 31.6877 88.8404 32.2294V35.9174C88.8404 36.2369 88.8265 36.4799 88.7987 36.6466C88.7848 36.7994 88.7223 36.9731 88.6112 37.1675C88.4306 37.5426 87.8959 37.7301 87.0069 37.7301C86.1317 37.7301 85.597 37.5426 85.4025 37.1675C85.3053 36.9592 85.2428 36.7786 85.215 36.6258C85.2011 36.4591 85.1941 36.216 85.1941 35.8965V28.5207C85.1941 28.2151 85.2011 27.9859 85.215 27.8331C85.2428 27.6664 85.3122 27.4858 85.4233 27.2913C85.6178 26.9441 86.1526 26.7704 87.0277 26.7704C87.875 26.7704 88.3959 26.9302 88.5904 27.2497C88.7293 27.4858 88.7987 27.7706 88.7987 28.1039C88.8543 28.0067 88.9793 27.8678 89.1738 27.6872C89.3682 27.5066 89.5558 27.3608 89.7363 27.2497C90.2086 26.9302 90.7295 26.7704 91.299 26.7704Z"
					fill="#252525"
				/>
				<path
					d="M104.289 32.2503L107.06 34.5422C107.407 34.8339 107.623 35.0562 107.706 35.209C107.803 35.3618 107.852 35.5076 107.852 35.6465C107.852 35.9799 107.637 36.4313 107.206 37.0008C106.734 37.5842 106.317 37.8759 105.956 37.8759C105.65 37.8759 105.227 37.6745 104.685 37.2717L104.477 37.1259C104.157 36.8619 103.664 36.4035 102.997 35.7507C102.33 35.0839 101.823 34.6117 101.476 34.3339V35.9382C101.476 36.2577 101.462 36.5008 101.435 36.6675C101.421 36.8203 101.358 37.0008 101.247 37.2092C101.066 37.5704 100.532 37.7509 99.6426 37.7509C98.7675 37.7509 98.2327 37.5634 98.0383 37.1884C97.9411 36.98 97.8785 36.7994 97.8508 36.6466C97.8369 36.4799 97.8299 36.2369 97.8299 35.9174V23.4367C97.8299 23.1172 97.8369 22.8811 97.8508 22.7283C97.8785 22.5616 97.9411 22.3741 98.0383 22.1657C98.2327 21.7907 98.7745 21.6032 99.6635 21.6032C100.33 21.6032 100.803 21.7212 101.08 21.9574C101.316 22.1518 101.441 22.4574 101.455 22.8741C101.469 22.9992 101.476 23.1936 101.476 23.4575V30.0625C101.726 29.8541 102.101 29.4999 102.601 28.9999C103.115 28.4998 103.497 28.1456 103.747 27.9372L103.997 27.7706C104.484 27.3955 104.9 27.208 105.247 27.208C105.595 27.208 105.991 27.4928 106.435 28.0623C106.894 28.6179 107.123 29.0554 107.123 29.3749C107.123 29.6944 106.866 30.0694 106.352 30.5001L104.289 32.2503Z"
					fill="#252525"
				/>
				<path
					d="M25.5201 44.5262C19.6663 45.1556 14.552 40.6613 14.552 34.8878V18.9733C14.552 17.0541 16.1389 15.4983 18.0965 15.4983C20.054 15.4983 21.641 17.0541 21.641 18.9733V33.9198C21.641 35.5173 22.6265 36.9561 24.1348 37.5606L25.9912 38.3046C26.5945 38.5464 26.9887 39.1219 26.9887 39.7609V42.9198C26.9887 43.7441 26.3559 44.4363 25.5201 44.5262Z"
					fill="white"
				/>
				<path
					d="M13.1077 25.882C13.1077 28.0543 11.3115 29.8153 9.09581 29.8153C6.88012 29.8153 5.08394 28.0543 5.08394 25.882C5.08394 23.7097 6.88012 21.9488 9.09581 21.9488C11.3115 21.9488 13.1077 23.7097 13.1077 25.882Z"
					fill="white"
				/>
				<path
					d="M30.9204 25.882C30.9204 28.0543 29.1242 29.8153 26.9085 29.8153C24.6928 29.8153 22.8966 28.0543 22.8966 25.882C22.8966 23.7097 24.6928 21.9488 26.9085 21.9488C29.1242 21.9488 30.9204 23.7097 30.9204 25.882Z"
					fill="white"
				/>
				<defs>
					<linearGradient
						id="paint0_linear_682_327"
						x1="53.926"
						y1="7.23"
						x2="53.926"
						y2="45.3"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#2269F8" />
						<stop offset="1" stopColor="#940686" />
					</linearGradient>
				</defs>
			</svg>
		</SvgIcon>
	);
};

export default LogoMain;
