import {
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Col, Row } from "../utils/styledComponents";
import HorizontalTile from "../components/CommonComponents/HorizontalTile";
import TonSymbol from "../assets/TonSymbol";
import PoolDetails from "../components/PoolPageComponents/PoolDetails/PoolDetails";
import PoolActionsWrapper from "../components/PoolPageComponents/ContractInteractions/PoolActionsWrapper";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { useNavigate } from "react-router-dom";
import VerticalTile from "../components/CommonComponents/VerticalTile";
import WifiOffRoundedIcon from "@mui/icons-material/WifiOffRounded";
import { PoolDataContext } from "../providers/PoolDataProvider";
import { createContext, useContext, useEffect, useState } from "react";
import useScrollToTop from "../hooks/useScrollToTop";
import { JettonWalletContext } from "../providers/JettonWalletProvider";
import { Address } from "ton-core";
import { IUserWallets } from "../types/interface";
import LPBreakdown from "../components/PoolPageComponents/PoolDetails/LPBreakdown";
import VaultDescription from "../components/PoolPageComponents/PoolDetails/VaultDescription";
import SafetyScoreWrapper from "../components/PoolPageComponents/PoolDetails/SafetyScore/SafetyScoreWrapper";
import IconsWrapper from "../components/CommonComponents/IconsWrapper";
import { useTonConnectUI } from "@tonconnect/ui-react";
interface UserWalletsContextType {
  userWallets: IUserWallets;
}
//TODO: export to separate file
export const UserWalletsContext = createContext<UserWalletsContextType | null>(
  null
);

const PoolPage = () => {
  useScrollToTop();
  const navigate = useNavigate();
  const smalLDesktop = useMediaQuery("(max-width:1200px)");
  const mobile = useMediaQuery("(max-width:600px)");
  const context = useContext(PoolDataContext);
  const walletContext = useContext(JettonWalletContext);
  const [tonConnectUI] = useTonConnectUI();

  const [userWallets, setUserWallets] = useState<IUserWallets>({
    lp: null,
    token1: null,
    token2: null,
    vault: null,
  });
  useEffect(
    () =>
      tonConnectUI.onStatusChange((wallet) => {
        console.log("wallet");
        console.log(wallet);
      }),
    []
  );
  useEffect(() => {
    context?.fetchOnDemand();
  }, []);
  useEffect(() => {
    if (
      walletContext &&
      context &&
      context.poolData &&
      context.poolData.address
    ) {
      if (walletContext.jettonWallets && context.poolData.address) {
        const vaultAddress = context.poolData.address;
        const lpAddress = context.poolData.lpToken.address;
        const token1 = context.poolData.token1.address;
        const token2 = context.poolData.token2.address;
        const walletData = walletContext.jettonWallets.find(
          (entry) =>
            entry.jetton ===
            Address.parse(vaultAddress).toRawString().toUpperCase()
        );
        const lpData = walletContext.jettonWallets.find(
          (entry) =>
            entry.jetton ===
            Address.parse(lpAddress as string)
              .toRawString()
              .toUpperCase()
        );
        const token1Data = walletContext.jettonWallets.find(
          (entry) =>
            entry.jetton ===
            Address.parse(token1 as string)
              .toRawString()
              .toUpperCase()
        );
        const token2Data = walletContext.jettonWallets.find(
          (entry) =>
            entry.jetton ===
            Address.parse(token2 as string)
              .toRawString()
              .toUpperCase()
        );
        if (token1Data && token2Data) {
          setUserWallets((prev) => {
            return { ...prev, token1: token1Data, token2: token2Data };
          });
        }
        if (lpData) {
          setUserWallets((prev) => {
            return {
              ...prev,
              lp: lpData,
            };
          });
        }
        if (walletData) {
          setUserWallets((prev) => {
            return {
              ...prev,
              vault: walletData,
            };
          });
        }
      }
    }
  }, [context, walletContext]);
  if (context?.loading) {
    return (
      <Grid container columnSpacing={0} spacing={0}>
        <Col
          justify="center"
          align="center"
          sx={{ height: "80vh", width: "100%" }}
        >
          <CircularProgress size={100} />
        </Col>
      </Grid>
    );
  } else if (!context?.poolData && !context?.loading) {
    return (
      <Grid container columnSpacing={0} spacing={0}>
        <Col
          justify="center"
          align="center"
          sx={{ height: "80vh", width: "100%" }}
        >
          <WifiOffRoundedIcon
            sx={{ fontSize: "5rem", color: "primary.main" }}
          />
          <Typography variant="h1">Unable to fetch data</Typography>
          <Typography variant="body1">Try again later</Typography>
        </Col>
      </Grid>
    );
  } else {
    return (
      <Grid container columnSpacing={2} spacing={2}>
        <Grid item xs={12} lg={12}>
          <Col gap={1} align="flex-start" sx={{ flexWrap: "wrap" }}>
            <Row
              align="center"
              gap={mobile ? 1 : 2}
              paddingTop="0.5rem"
              paddingBottom="0.5rem"
            >
              <IconButton
                onClick={() => navigate(`/`)}
                sx={{
                  backgroundColor: "primary.transparent",
                  color: "primary.main",
                }}
              >
                <ArrowBackRoundedIcon />
              </IconButton>

              {context.poolData && (
                <IconsWrapper
                  icon1={context.poolData.token1.icon}
                  icon2={context.poolData.token2.icon}
                />
              )}

              <Typography variant="h1">{`${context.poolData?.token1?.symbol}-${context.poolData?.token2?.symbol}`}</Typography>
              {!mobile && <Typography variant="h1"> Vault</Typography>}
            </Row>
            {!mobile ? (
              <Row gap={4} align="center" sx={{ width: "100%" }}>
                <HorizontalTile
                  label="Chain:"
                  text={<TonSymbol height="50" width="50" />}
                />
                <HorizontalTile label="Platform:" text={`Tonk pool provider`} />
              </Row>
            ) : (
              <Row
                gap={1}
                align="center"
                justify="space-between"
                sx={{ width: "100%" }}
              >
                <VerticalTile
                  label="Chain:"
                  text={<TonSymbol height="1rem" width="1rem" />}
                />
                <VerticalTile label="Platform:" text={`Tonk pool provider`} />
              </Row>
            )}
          </Col>
        </Grid>
        {smalLDesktop && (
          <Grid item xs={12} lg={12}>
            <UserWalletsContext.Provider value={{ userWallets }}>
              <PoolActionsWrapper />
            </UserWalletsContext.Provider>
          </Grid>
        )}
        <Grid item xs={12} lg={8}>
          <Col gap={2}>
            <PoolDetails />

            <Col
              sx={{
                backgroundColor: "background.paper",
                width: "100%",
                borderRadius: "25px",
                boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
                minHeight: "18rem",
                gap: 2,
              }}
              justify="center"
              align="center"
            >
              <LPBreakdown />
            </Col>

            <Col
              sx={{
                backgroundColor: "background.paper",
                width: "100%",
                borderRadius: "25px",
                boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
                minHeight: "18rem",
                gap: 2,
              }}
              justify="center"
              align="center"
            >
              <SafetyScoreWrapper />
            </Col>

            <Col
              sx={{
                backgroundColor: "background.paper",
                width: "100%",
                borderRadius: "25px",
                boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
                // height: "20rem",
                gap: 2,
              }}
              justify="center"
              align="center"
            >
              <VaultDescription />
            </Col>
          </Col>
        </Grid>

        {!smalLDesktop && (
          <Grid item lg={4}>
            <UserWalletsContext.Provider value={{ userWallets }}>
              <PoolActionsWrapper />
            </UserWalletsContext.Provider>
          </Grid>
        )}
      </Grid>
    );
  }
};

export default PoolPage;
