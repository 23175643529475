import { api } from "../utils/apiConfig";
import { URL_PRICE } from "../utils/url";

const GetPrice = async () => {
	return api
		.get(`${URL_PRICE}`)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			return err;
		});
};

export default GetPrice;

// const GetPrice = async () => {
// 	const mock = new MockAdapter(api);
// 	const response = mock.onGet(URL_PRICE).reply(function (config) {
// 		return [
// 			200,
// 			{
// 				data: [
// 					{
// 						fiatValue: 1.028,
// 						fiatCurrency: "usd",
// 						cryptoCurrency: "bridged-tether-ton-bridge",
// 						symbol: "tkUSDT",
// 					},
// 					{
// 						fiatValue: 1.078,
// 						fiatCurrency: "usd",
// 						cryptoCurrency: "bridged-usd-coin-ton-bridge",
// 						symbol: "tkUSDC",
// 					},
// 					{
// 						fiatValue: 2.21,
// 						fiatCurrency: "usd",
// 						cryptoCurrency: "the-open-network",
// 						symbol: "tkWTON",
// 					},
// 					{
// 						fiatValue: 2.21,
// 						fiatCurrency: "usd",
// 						cryptoCurrency: "tonk",
// 						symbol: "tkTONK",
// 					},
// 				],
// 			},
// 		];
// 	});
// 	console.log("response", response);
// 	return api
// 		.get(URL_PRICE)
// 		.then((res) => {
// 			console.log(res);
// 			return res;
// 		})
// 		.catch((err) => {
// 			console.error(err);
// 			return err;
// 		});
// };
// export default GetPrice;
