import { useEffect, useState } from "react";
import GetPools from "../api/GetPools";
import { IPool } from "../types/interface";

const usePools = () => {
	const [pools, setPools] = useState<IPool[] | null>(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<Error | string>("");
	const fetchPools = async () => {
		try {
			const response = await GetPools();
			if (response) {
				setPools(response.data);
			}
		} catch (err) {
			setError(err instanceof Error ? err : new Error(String(err)));
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		fetchPools();
	}, []);
	return { loading, error, pools, fetchPools };
};

export default usePools;
