import { ThemeProvider } from "@emotion/react";
import "./App.css";
import Layout from "./page/Layout";
import theme from "./providers/palette";
import { JettonWalletProvider } from "./providers/JettonWalletProvider";
import { useContext, useEffect } from "react";
import { useTonWallet } from "@tonconnect/ui-react";
import { setUserProperties } from "@firebase/analytics";
import { AnalyticsContext } from "./providers/AnalyticsProvider";

function App() {
	const wallet = useTonWallet();
	const analyticsContext = useContext(AnalyticsContext);

	useEffect(() => {
		if (wallet && analyticsContext) {
			setUserProperties(analyticsContext.analytics, {
				wallet: wallet.account.address,
			});
		}
	}, [wallet]);
	return (
		<div>
			<ThemeProvider theme={theme}>
				<JettonWalletProvider>
					{/* {isLoading ? (
            <Col
              justify="center"
              align="center"
              sx={{ width: "100%", height: "100vh" }}
            >
              <CircularProgress size={100} />
            </Col>
          ) : ( */}
					<Layout />
					{/* )} */}
				</JettonWalletProvider>
			</ThemeProvider>
		</div>
	);
}

export default App;
