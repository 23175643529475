import { Box, Drawer, IconButton, Typography } from "@mui/material";
import { IDrawerMenuProps } from "../../types/interface";
import { Col, Row, StyledLink } from "../../utils/styledComponents";
import LogoMain from "../../assets/LogoMain";
import CloseIcon from "@mui/icons-material/Close";
import ButtonRound from "../CommonComponents/ButtonRound";
const DrawerMenu: React.FC<IDrawerMenuProps> = ({
  open,
  handleClose,
  onClick,
}) => {
  return (
    <Drawer
      anchor="right"
      onClose={handleClose}
      open={open}
      sx={{
        width: "100%",
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: "80%",
          boxSizing: "border-box",
          paddingLeft: "3rem",
          paddingRight: "3rem",
          paddingTop: "1rem",
          paddingBottom: "1rem",
        },
        [`& .MuiBackdrop-root`]: {
          backdropFilter: "blur(5px)",
          backgroundColor: "rgba(0,0,30,0.4)",
        },
      }}
    >
      <Row justify="space-between">
        <Box style={{ flexGrow: 1 }}>
          <LogoMain height="6rem" width="6rem" />
        </Box>
        <IconButton onClick={handleClose}>
          <CloseIcon sx={{ fontSize: "2rem" }} />
        </IconButton>
      </Row>
      <Col sx={{ gap: 3 }}>
        <StyledLink
          to="/"
          onClick={handleClose}
          style={({ isActive, isPending, isTransitioning }) => {
            return {
              fontWeight: isActive ? "600" : "",
              viewTransitionName: isTransitioning ? "slide" : "",
            };
          }}
        >
          <Typography variant="body1">Vaults</Typography>
        </StyledLink>
        <StyledLink
          onClick={handleClose}
          to="account"
          style={({ isActive, isPending, isTransitioning }) => {
            return {
              fontWeight: isActive ? "600" : "",
              viewTransitionName: isTransitioning ? "slide" : "",
            };
          }}
        >
          <Typography variant="body1">Manage</Typography>
        </StyledLink>
        <StyledLink
          to="https://docs.tonk.finance/tonk-finance/"
          style={({ isActive, isPending, isTransitioning }) => {
            return {
              fontWeight: isActive ? "600" : "",
              viewTransitionName: isTransitioning ? "slide" : "",
            };
          }}
        >
          <Typography variant="body1">Docs</Typography>
        </StyledLink>
        <ButtonRound
          disabled={false}
          onClick={onClick}
          text="Get testnet jettons"
        />
      </Col>
    </Drawer>
  );
};

export default DrawerMenu;
