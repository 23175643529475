import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { Col, Row } from "../utils/styledComponents";
import { useTonWallet } from "@tonconnect/ui-react";
import WalletTile from "../components/CommonComponents/WalletTile";
import VaultIcon from "../assets/VaultIcon";
import WalletIcon from "../assets/WalletIcon";
import CoinsIcon from "../assets/CoinsIcon";
import SingleCoinIcon from "../assets/SingleCoinIcon";
const AccountPage = () => {
  const wallet = useTonWallet();
  const tablet = useMediaQuery("(max-width:900px)");
  const mobile = useMediaQuery("(max-width:600px)");
  return (
    <Grid container columnSpacing={2} spacing={2}>
      <Grid item xs={12} lg={12}>
        <Row align="baseline" gap={2}>
          <Typography variant="h1">Vault</Typography>
          <Typography variant="h2" fontWeight="regular">
            {wallet
              ? `(
						${wallet.account.address.slice(0, 6)}...${wallet.account.address.slice(-5)}
						)`
              : "(Wallet not connected)"}
          </Typography>
        </Row>
      </Grid>
      <Grid item xs={6} md={6} lg={3}>
        <Col gap={3}>
          <WalletTile
            label="Your deposits"
            text={"$ 0"}
            icon={
              <Box
                sx={{
                  backgroundColor: "primary.transparent",
                  borderRadius: "100vh",
                  padding: "6%",
                  color: "primary.main",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <VaultIcon
                  height={mobile ? "0.8rem" : "1.5rem"}
                  width={mobile ? "0.8rem" : "1.5rem"}
                />
              </Box>
            }
          />
        </Col>
      </Grid>
      <Grid item xs={6} md={6} lg={3}>
        <Col gap={3}>
          <WalletTile
            label="Vaults"
            text={"0"}
            icon={
              <Box
                sx={{
                  backgroundColor: "primary.transparent",
                  borderRadius: "100vh",
                  padding: "6%",
                  color: "primary.main",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <WalletIcon
                  height={mobile ? "0.8rem" : "1.5rem"}
                  width={mobile ? "0.8rem" : "1.5rem"}
                />
              </Box>
            }
          />
        </Col>
      </Grid>
      <Grid item xs={6} md={6} lg={3}>
        <Col gap={3}>
          <WalletTile
            label="Yield"
            text={"$ 0"}
            icon={
              <Box
                sx={{
                  backgroundColor: "primary.transparent",
                  borderRadius: "100vh",
                  padding: "6%",
                  color: "primary.main",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CoinsIcon
                  height={mobile ? "0.8rem" : "1.5rem"}
                  width={mobile ? "0.8rem" : "1.5rem"}
                />
              </Box>
            }
          />
        </Col>
      </Grid>
      <Grid item xs={6} md={6} lg={3}>
        <Col gap={3}>
          <WalletTile
            label="Est. daily yield"
            text={"$ 0"}
            icon={
              <Box
                sx={{
                  backgroundColor: "primary.transparent",
                  borderRadius: "100vh",
                  padding: "6%",
                  color: "primary.main",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <SingleCoinIcon
                  height={mobile ? "0.8rem" : "1.5rem"}
                  width={mobile ? "0.8rem" : "1.5rem"}
                />
              </Box>
            }
          />
        </Col>
      </Grid>
      <Grid xs={12} lg={12} item>
        <Col
          sx={{
            backgroundColor: "background.paper",
            padding: "1rem",
            borderRadius: "25px",
            boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
            height: "25rem",
            gap: 2,
          }}
          align="center"
          justify="center"
        >
          <Typography variant="body1">Coming soon</Typography>
        </Col>
      </Grid>
    </Grid>
  );
};

export default AccountPage;
