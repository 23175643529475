import React from "react";
import { Row } from "../../utils/styledComponents";
interface IIconsWrapper {
  icon1: string;
  icon2: string;
}
const IconsWrapper: React.FC<IIconsWrapper> = ({ icon1, icon2 }) => {
  return (
    <Row>
      <img
        height="50"
        width="50"
        src={icon1}
        style={{ zIndex: "5" }}
        alt={`icon-${Math.random() * 2}`}
      />
      <img
        height="50"
        width="50"
        style={{ marginLeft: "-25px" }}
        src={icon2}
        alt={`icon-${Math.random() * 2}`}
      />
    </Row>
  );
};

export default IconsWrapper;
