import React, {
	createContext,
	Dispatch,
	ReactNode,
	SetStateAction,
	useEffect,
	useState,
} from "react";

// Assuming IPool is defined somewhere
import { IJettonWallet } from "../types/interface";
import { useTonWallet } from "@tonconnect/ui-react";
import GetJettonWallets from "../api/GetJettonWallets";

export interface JettonWalletContext {
	fetchOnTrigger: (
		userAddress: string,
		setter: Dispatch<SetStateAction<boolean>>
	) => Promise<void>;
	jettonWallets: IJettonWallet[] | null;
	loadingWallets: boolean;
	errorWallets: Error | string;
}

// Create a context
export const JettonWalletContext = createContext<JettonWalletContext | null>(
	null
);
interface JettonWalletProviderProps {
	children: ReactNode;
}
export const JettonWalletProvider: React.FC<JettonWalletProviderProps> = ({
	children,
}) => {
	const wallet = useTonWallet();
	const userAddress = wallet?.account.address;
	const [jettonWallets, setJettonWallet] = useState<IJettonWallet[] | null>(
		null
	);
	const [loadingWallets, setLoading] = useState(false);
	const [errorWallets, setError] = useState<Error | string>("");
	const fetchOnTrigger = async (
		userAddress: string,
		setter: Dispatch<SetStateAction<boolean>>
	) => {
		setter(true);
		try {
			const response = await GetJettonWallets(userAddress);
			if (response) {
				console.log(response.data);
				setJettonWallet(response.data.jetton_wallets);
			}
		} catch (err) {
			setError(err instanceof Error ? err : new Error(String(err)));
		} finally {
			setter(false);
		}
	};
	const fetchJettonWallets = async (userAddress: string) => {
		setLoading(true);
		try {
			const response = await GetJettonWallets(userAddress);
			if (response) {
				console.log(response.data);
				setJettonWallet(response.data.jetton_wallets);
			}
		} catch (err) {
			setError(err instanceof Error ? err : new Error(String(err)));
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		if (userAddress) {
			fetchJettonWallets(userAddress);
		}
	}, [userAddress, wallet]);
	return (
		<JettonWalletContext.Provider
			value={{ jettonWallets, loadingWallets, errorWallets, fetchOnTrigger }}
		>
			{children}
		</JettonWalletContext.Provider>
	);
};
