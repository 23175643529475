import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { IMobileCategorySelector } from "../../types/interface";
import { Typography } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MobileCategorySelector: React.FC<IMobileCategorySelector> = ({
  tabs,
  tab,
  handleChange,
}) => {
  return (
    <FormControl sx={{ width: "100%" }}>
      <Select
        // multiple
        displayEmpty
        value={tab}
        onChange={handleChange}
        input={
          <OutlinedInput
            sx={{
              backgroundColor: "background.paper",
              border: "solid",
              borderColor: "rgba(0,0,0,0.05)",
              borderRadius: "10px",
              // display: "flex",
              color: "black",

              "&.Mui-focused": {
                borderColor: "primary.main",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          />
        }
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>Vault type</em>;
          }
          if (selected === "all") {
            return "All categories";
          } else {
            const selectedTabLabel = tabs.map((entry, index) => {
              if (entry.value === selected) {
                return entry.label;
              }
            });
            return selectedTabLabel;
          }
        }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem disabled value="">
          <em>Vault type</em>
        </MenuItem>
        {tabs.map((entry, index) => (
          <MenuItem key={index} value={entry.value}>
            <Typography
              variant="body1"
              fontWeight={tab === entry.value ? "600" : "regular"}
            >
              {entry.label}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default MobileCategorySelector;
