import { styled } from "@mui/material/styles";
import Tabs, { TabsProps } from "@mui/material/Tabs";
import Tab, { TabProps } from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { ICategorySelector } from "../../types/interface";

const StyledTabs = styled((props: TabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  "& .MuiTabs-scroller": {
    border: "solid",

    borderColor: "rgba(0,0,0,0.05)",
    borderRadius: "25px",
    backgroundColor: "primary.main",
  },
  "& .MuiTabs-indicator": {
    // display: "none",
    height: "100%",
    borderRadius: "25px",
    zIndex: "1",
    backgroundColor: "primary.main",
  },
  "& .MuiTabs-indicatorSpan": {
    // display: "none",
    // height: "100%",
  },
}));

const StyledTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    lineHeight: "1rem",
    backgroundColor: "background.paper",

    color: theme.palette.text.primary,
    "&.Mui-selected": {
      color: "#fff",
      backgroundColor: "transparent",
      borderRadius: "25px",
      zIndex: "2",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

const CategoryTabs: React.FC<ICategorySelector> = ({
  tabs,
  category,
  handleChange,
}) => {
  return (
    <Box sx={{ width: `${tabs.length * 8}rem` }}>
      <StyledTabs
        value={category}
        onChange={handleChange}
        aria-label="styled tabs example"
        variant="fullWidth"
      >
        {tabs.map((entry, index) => {
          return (
            <StyledTab
              value={entry.value}
              key={`tab-${index}`}
              label={entry.label}
            />
          );
        })}
      </StyledTabs>
    </Box>
  );
};
export default CategoryTabs;
