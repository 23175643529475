import {
	Box,
	Button,
	Container,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "../components/LayoutComponents/Header";
import Footer from "../components/LayoutComponents/Footer";
import { Col } from "../utils/styledComponents";
import { useTonWallet } from "@tonconnect/ui-react";
import CookieConsent from "react-cookie-consent";
const Layout = () => {
	const tablet = useMediaQuery("(max-width:1080px)");

	const wallet = useTonWallet();
	return (
		<>
			<div
				style={{
					paddingLeft: tablet ? "2%" : "10%",
					paddingRight: tablet ? "2%" : "10%",
					paddingTop: tablet ? "1rem" : 0,
					paddingBottom: tablet ? "1rem" : 0,
				}}
			>
				<Header />
				<Container maxWidth="lg">
					<Box my={4}>
						{wallet && wallet.account.chain !== "-3" && (
							<Col sx={{ width: "100%", alignItems: "center" }}>
								<Col
									sx={{
										backgroundColor: "error.main",
										// width: "30rem",
										alignSelf: "center",
										padding: "1rem",
										borderRadius: "25px",
										border: "solid",
										borderColor: "error.main",
										justifyContent: "space-evenly",
										gap: 1,
									}}
								>
									<Typography
										variant="h2"
										sx={{
											textAlign: "center",
											textTransform: "uppercase",
											fontWeight: "550",
											color: "white",
										}}
									>
										You are not using testnet wallet
									</Typography>
									<Typography
										variant="body2"
										sx={{
											textAlign: "center",
											textTransform: "uppercase",
											color: "rgba(255,255,255,0.6)",
											fontSize: "1rem",
										}}
									>
										{"You won't be able to interact with our platform"}
									</Typography>
									<Button
										onClick={() =>
											window.open(
												"https://docs.tonk.finance/tonk-finance/get-started-with-testnet/how-to-switch-wallet-to-testnet"
											)
										}
										variant="outlined"
										sx={{
											color: "white",
											borderColor: "white",
											"&:hover": {
												borderColor: "rgba(255,255,255,0.6)",
											},
										}}
									>
										Learn how to setup testnet wallet
									</Button>
								</Col>
							</Col>
						)}
						<Outlet />
					</Box>
				</Container>
				<CookieConsent
					style={{
						background: "#252525",
						fontFamily: "Fredoka, sans-serif",
					}}
					buttonStyle={{
						background: "#2269F8",
						color: "white",
						borderRadius: "10px",
						padding: "0.8rem",
						paddingTop: "0.5rem",
						paddingBottom: "0.5rem",
						fontFamily: "Fredoka, sans-serif",
					}}
				>
					<Typography variant="h3" sx={{ color: "white" }}>
						We are using cookies to improve our platform and find bugs quicker.
					</Typography>
					<Typography
						variant="subtitle1"
						sx={{ opacity: 0.6, fontWeight: "300" }}
					>
						You accept the cookies by continuing using Tonk Finance platform.
					</Typography>
				</CookieConsent>
			</div>
			<Footer />
		</>
	);
};

export default Layout;
