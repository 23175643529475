import { Col } from "../../../utils/styledComponents";
import CustomInput from "../../CommonComponents/CustomInput";
import { ILPWrapper } from "../../../types/interface";
import DepositLPButton from "./DepositLPButton";
import ButtonRectangular from "../../CommonComponents/ButtonRectangular";
import { useContext } from "react";
import { PoolDataContext } from "../../../providers/PoolDataProvider";
import WithdrawLPButton from "./WithdrawLPButton";
import { useTonWallet } from "@tonconnect/ui-react";

const DepositLPWrapper: React.FC<ILPWrapper> = ({
  handleChangePair,
  jettonsInput,
  jettonsAvailable,
  userAddress,
  fetching,
  handleModal,
}) => {
  const context = useContext(PoolDataContext);
  const wallet = useTonWallet();

  return (
    <Col gap={2} justify="space-between" sx={{ height: "14rem" }}>
      <Col gap={2}>
        <CustomInput
          token={context?.poolData?.lpToken}
          handleChangePair={handleChangePair}
          jettonsAvailable={jettonsAvailable.lp}
          jettonsInput={jettonsInput.lp}
          name="lp"
          fetching={fetching}
        />
      </Col>
      {wallet ? (
        jettonsInput.lp === 0 || isNaN(jettonsInput.lp) ? (
          <ButtonRectangular
            disabled={true}
            onClick={() => console.error("Enter jettons amount")}
            text="Deposit"
          />
        ) : (
          context?.poolData &&
          userAddress && (
            <Col sx={{ width: "100%" }} gap={1} justify="space-between">
              <DepositLPButton
                userAddress={userAddress}
                masterAddress={context.poolData.lpToken.address}
                vaultAddress={context.poolData.address}
                inputAmount={jettonsInput.lp}
                handleModal={handleModal}
              />
              <WithdrawLPButton
                userAddress={userAddress}
                token1={context.poolData.token1.address}
                token2={context.poolData.token2.address}
                withdraw={context.poolData.lpToken.address}
                amount={jettonsInput.lp}
                handleModal={handleModal}
              />
            </Col>
          )
        )
      ) : (
        <ButtonRectangular
          disabled={true}
          onClick={() => console.error("Wallet not connected")}
          text="Wallet not connected"
        />
      )}
    </Col>
  );
};

export default DepositLPWrapper;
