export interface IFactor {
  score: number;
  tag: string;
  title: string;
  description: string;
  arrowDirection: string;
  color: string;
}

export interface IFactors {
  impLoss?: IFactor;
  liquidity?: IFactor;
  mCap?: IFactor;
  platform: IFactor;
}

export interface IPoolByScore {
  id: string;
  factors: IFactors;
  total: number;
}
export const poolsByScore = [
  {
    id: "FUy9Dqoasw4PDkrpWzZo",
    total: -65,
    factors: {
      impLoss: {
        score: -30,
        tag: "HIGH_IL",
        title: "High Projected IL",
        description:
          "For vaults with significant IL risk, typically involving governance tokens or less correlated pairs.",
        arrowDirection: "down",
        color: "#D32F2F",
      },
      liquidity: {
        score: -15,
        tag: "LOW_LIQ",
        title: "Low Trade Liquidity",
        description:
          "Indicates assets with lower liquidity, potentially leading to higher slippage during trades.",
        arrowDirection: "down",
        color: "#D32F2F",
      },
      mCap: {
        score: -20,
        tag: "S_CAP",
        title: "Small Market Cap",
        description:
          "Points to a high-risk asset with small market cap and high volatility.",
        arrowDirection: "down",
        color: "#D32F2F",
      },
      platform: {
        score: 0,
        tag: "EST_PLATFORM",
        title: "Established Platform",
        description:
          "For platforms with a known track record, implying lower risk.",
        arrowDirection: "up",
        color: "#33CC99",
      },
    },
  },
  {
    id: "I1kndrttinPVjpqC5sPP",
    total: -50,
    factors: {
      impLoss: {
        score: -15,
        tag: "LOW_IL",
        title: "Low Projected IL",
        description:
          "Applies to vaults with some risk of IL, particularly with common liquidity pairs.",
        arrowDirection: "up",
        color: "#33CC99",
      },
      liquidity: {
        score: -15,
        tag: "LOW_LIQ",
        title: "Low Trade Liquidity",
        description:
          "Indicates assets with lower liquidity, potentially leading to higher slippage during trades.",
        arrowDirection: "down",
        color: "#D32F2F",
      },
      mCap: {
        score: -20,
        tag: "S_CAP",
        title: "Small Market Cap",
        description:
          "Points to a high-risk asset with small market cap and high volatility.",
        arrowDirection: "down",
        color: "#D32F2F",
      },
      platform: {
        score: 0,
        tag: "EST_PLATFORM",
        title: "Established Platform",
        description:
          "For platforms with a known track record, implying lower risk.",
        arrowDirection: "up",
        color: "#33CC99",
      },
    },
  },
  {
    id: "Xgcln1A8JHZIo1Gc2X5t",
    total: 0,
    factors: {
      impLoss: {
        score: 0,
        tag: "ZERO_IL",
        title: "Very Low or Zero Projected IL",
        description:
          "Ideal for single asset vaults or stablecoin pairs with minimal expected IL.",
        arrowDirection: "doubleup",
        color: "#33CC99",
      },
      liquidity: {
        score: 0,
        tag: "HIGH_LIQ",
        title: "High Trade Liquidity",
        description:
          "Denotes assets with high liquidity, facilitating easier trades.",
        arrowDirection: "up",
        color: "#33CC99",
      },
      mCap: {
        score: 0,
        tag: "L_CAP",
        title: "Large Market Cap",
        description:
          "Signifies a low-risk asset with high market cap and low volatility.",
        arrowDirection: "doubleup",
        color: "#33CC99",
      },
      platform: {
        score: 0,
        tag: "EST_PLATFORM",
        title: "Established Platform",
        description:
          "For platforms with a known track record, implying lower risk.",
        arrowDirection: "up",
        color: "#33CC99",
      },
    },
  },
  {
    id: "aksARJ0OEdPOkKSDSS0x",
    total: -15,
    factors: {
      impLoss: {
        score: -15,
        tag: "LOW_IL",
        title: "Low Projected IL",
        description:
          "Applies to vaults with some risk of IL, particularly with common liquidity pairs.",
        arrowDirection: "up",
        color: "#33CC99",
      },
      liquidity: {
        score: 0,
        tag: "HIGH_LIQ",
        title: "High Trade Liquidity",
        description:
          "Denotes assets with high liquidity, facilitating easier trades.",
        arrowDirection: "up",
        color: "#33CC99",
      },
      mCap: {
        score: 0,
        tag: "L_CAP",
        title: "Large Market Cap",
        description:
          "Signifies a low-risk asset with high market cap and low volatility.",
        arrowDirection: "doubleup",
        color: "#33CC99",
      },
      platform: {
        score: 0,
        tag: "EST_PLATFORM",
        title: "Established Platform",
        description:
          "For platforms with a known track record, implying lower risk.",
        arrowDirection: "up",
        color: "#33CC99",
      },
    },
  },
  {
    id: "lZnZW1OoLxG9ThJ2tH6l",
    total: -15,
    factors: {
      impLoss: {
        score: -15,
        tag: "LOW_IL",
        title: "Low Projected IL",
        description:
          "Applies to vaults with some risk of IL, particularly with common liquidity pairs.",
        arrowDirection: "up",
        color: "#33CC99",
      },
      liquidity: {
        score: 0,
        tag: "HIGH_LIQ",
        title: "High Trade Liquidity",
        description:
          "Denotes assets with high liquidity, facilitating easier trades.",
        arrowDirection: "up",
        color: "#33CC99",
      },
      mCap: {
        score: 0,
        tag: "L_CAP",
        title: "Large Market Cap",
        description:
          "Signifies a low-risk asset with high market cap and low volatility.",
        arrowDirection: "doubleup",
        color: "#33CC99",
      },
      platform: {
        score: 0,
        tag: "EST_PLATFORM",
        title: "Established Platform",
        description:
          "For platforms with a known track record, implying lower risk.",
        arrowDirection: "up",
        color: "#33CC99",
      },
    },
  },
];
