import { Typography } from "@mui/material";
import { IHorizontalTile } from "../../types/interface";
import { Col } from "../../utils/styledComponents";

const VerticalTile: React.FC<IHorizontalTile> = ({ label, text }) => {
  return (
    <Col
      sx={{
        backgroundColor: "background.paper",
        padding: "1rem",
        borderRadius: "10px",
        boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
        gap: 1,
        flexGrow: 1,
        maxHeight: "4rem",
      }}
    >
      <Typography variant="h3" color="primary.main">
        {label}
      </Typography>
      <Typography variant="h3">{text}</Typography>
    </Col>
  );
};

export default VerticalTile;
