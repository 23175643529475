import { SafeAddress } from "@tonk-finance/sdk";
import { generateUniqueBigInt } from "./utils";
import { createDeploy, depositPair } from "./contractConstructors";
import { toNano } from "ton-core";
import { ExtendedMessage, Message } from "../types/interface";
import {
	depositLPBody,
	withdrawBody,
	withdrawJettonBody,
} from "./bodyConstructors";
import CheckContractDeployed from "../api/CheckContractDeployed";

export const sendDepositPair = async (
	userAddress: SafeAddress,
	targetTokenAddress: SafeAddress,
	token0Address: SafeAddress,
	token1Address: SafeAddress,
	jettonWallet1: string,
	jettonWallet2: string,
	amount0: number,
	amount1: number
) => {
	console.log(targetTokenAddress);
	try {
		const response = await depositPair(
			targetTokenAddress,
			token0Address,
			token1Address,
			userAddress,
			amount0,
			amount1
		);
		if (response && jettonWallet1 && jettonWallet2) {
			const isDeployed = await CheckContractDeployed(response.exchange);
			console.log(isDeployed);
			// const isDeployed = await checkContractDeployed(response.exchange, client);
			const messages: (Message | ExtendedMessage)[] = [
				{
					address: jettonWallet1, // sender jetton wallet
					amount: toNano("0.5").toString(), // for commission fees, excess will be returned
					payload: response.createProviderJettonBody0, // payload with jetton transfer body
				},
				{
					address: jettonWallet2, // sender jetton wallet
					amount: toNano("0.5").toString(), // for commission fees, excess will be returned
					payload: response.createProviderJettonBody1, // payload with jetton transfer body
				},
			];

			if (!isDeployed) {
				messages.unshift({
					address: response.exchange, // sender jetton wallet
					amount: toNano("0.05").toString(), // for commission fees, excess will be returned
					payload: response.createDeploy, // payload with jetton transfer body
					stateInit: response.contractInit,
				} as ExtendedMessage); // Use 'as ExtendedMessage' for type assertion
			}

			const transaction = {
				validUntil: Date.now() + 5 * 60 * 1000,
				messages: messages,
			};
			return transaction;
		}
	} catch (err) {
		const error = err as Error;
		throw new Error(error.message);
	}
};

export const sendDepositLP = async (
	userAddress: SafeAddress,
	userJettonWalletContract: string,
	vaultAddress: SafeAddress,
	amount: number
	// destination: SafeAddress,
	// responseDestination: SafeAddress
) => {
	try {
		const response = depositLPBody(amount, vaultAddress, userAddress);
		if (response && userJettonWalletContract) {
			const transaction = {
				validUntil: Date.now() + 5 * 60 * 1000,
				messages: [
					{
						address: userJettonWalletContract, // sender jetton wallet
						amount: toNano("0.3").toString(), // for commission fees, excess will be returned
						payload: response, // payload with jetton transfer body
					},
				],
			};
			return transaction;
		}
	} catch (err) {
		const error = err as Error;
		throw new Error(error.message);
	}
};

export const sendWithdrawVault = async (
	userJettonWalletContract: string,
	userAddress: SafeAddress,
	targetTokenAddress: SafeAddress,
	token0Address: SafeAddress,
	token1Address: SafeAddress,
	amount: number
) => {
	const contract = await createDeploy(
		targetTokenAddress,
		token0Address,
		token1Address,
		userAddress,
		generateUniqueBigInt()
	);
	if (contract) {
		const isDeployed = await CheckContractDeployed(contract.address);

		const responseWithdraw = withdrawBody(
			amount,
			userAddress,
			contract.address
		);
		if (responseWithdraw && userJettonWalletContract) {
			const transaction = {
				validUntil: Date.now() + 5 * 60 * 1000,
				messages: [
					{
						address: userJettonWalletContract, // sender jetton wallet
						amount: toNano("0.5").toString(), // for commission fees, excess will be returned
						payload: responseWithdraw, // payload with jetton transfer body
					},
				],
			};

			return transaction;
		}
	}
};
export const sendWithdrawLP = async (
	userJettonWalletContract: string,
	userAddress: SafeAddress,
	targetTokenAddress: SafeAddress,
	token0Address: SafeAddress,
	token1Address: SafeAddress,
	amount: number
) => {
	const contract = await createDeploy(
		targetTokenAddress,
		token0Address,
		token1Address,
		userAddress,
		generateUniqueBigInt()
	);
	if (contract) {
		const isDeployed = await CheckContractDeployed(contract.address);
		const responseWithdraw = withdrawJettonBody(
			amount,
			userAddress,
			contract.address
		);
		if (responseWithdraw && userJettonWalletContract) {
			const transaction = {
				validUntil: Date.now() + 5 * 60 * 1000,
				messages: [
					{
						address: userJettonWalletContract, // sender jetton wallet
						amount: toNano("0.8").toString(), // for commission fees, excess will be returned
						payload: responseWithdraw, // payload with jetton transfer body
					},
				],
			};

			return transaction;
		}
	}
};
