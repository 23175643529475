import React, { useContext } from "react";
import { Col } from "../../../utils/styledComponents";
import { ILPWrapper } from "../../../types/interface";
import CustomInput from "../../CommonComponents/CustomInput";
import ButtonRectangular from "../../CommonComponents/ButtonRectangular";
import { PoolDataContext } from "../../../providers/PoolDataProvider";
import WithdrawVaultButton from "./WithdrawVaultButton";
import { useTonWallet } from "@tonconnect/ui-react";

const WithdrawWrapper: React.FC<ILPWrapper> = ({
  handleChangePair,
  jettonsInput,
  jettonsAvailable,
  userAddress,
  fetching,
  handleModal,
}) => {
  const context = useContext(PoolDataContext);
  const wallet = useTonWallet();
  return (
    <Col gap={2} justify="space-between" sx={{ height: "14rem" }}>
      <Col gap={2}>
        <CustomInput
          token={context?.poolData?.lpToken}
          handleChangePair={handleChangePair}
          jettonsAvailable={jettonsAvailable.withdraw}
          jettonsInput={jettonsInput.withdraw}
          name="withdraw"
          fetching={fetching}
        />
      </Col>

      {wallet ? (
        jettonsInput.withdraw === 0 || isNaN(jettonsInput.withdraw) ? (
          <ButtonRectangular
            disabled={true}
            onClick={() => console.error("Enter jetton amount")}
            text="Withdraw to LP tokens"
          />
        ) : (
          context?.poolData &&
          userAddress && (
            <Col sx={{ width: "100%" }} gap={1} justify="space-between">
              <WithdrawVaultButton
                userAddress={userAddress}
                token1={context.poolData.token1.address}
                token2={context.poolData.token2.address}
                withdraw={context.poolData.address}
                amount={jettonsInput.withdraw}
                handleModal={handleModal}
              />
            </Col>
          )
        )
      ) : (
        <ButtonRectangular
          disabled={true}
          onClick={() => console.error("Wallet not connected")}
          text="Wallet not connected"
        />
      )}
    </Col>
  );
};

export default WithdrawWrapper;
