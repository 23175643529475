import { Typography } from "@mui/material";
import { IHorizontalTile } from "../../types/interface";
import { Row } from "../../utils/styledComponents";

const HorizontalTile: React.FC<IHorizontalTile> = ({ label, text }) => {
  return (
    <Row
      sx={{
        backgroundColor: "background.paper",
        padding: "1rem",
        borderRadius: "10px",
        alignItems: "center",
        boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
        gap: 2,
      }}
    >
      <Typography variant="h3" color="primary.main">
        {label}
      </Typography>
      <Typography variant="h3">{text}</Typography>
    </Row>
  );
};

export default HorizontalTile;
