import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
interface IProgress {
  progress: number;
}
const MobileScoreMeter: React.FC<IProgress> = ({ progress }) => {
  return (
    <Box sx={{ width: "70%", padding: "1rem" }}>
      <LinearProgress
        sx={{
          borderRadius: "25px",
          "&.MuiLinearProgress-root": {
            height: "10px",
            backgroundColor:
              progress >= 75
                ? "rgba(51, 204, 153, 0.25)"
                : progress < 75 && progress > 30
                ? "rgba(255, 176, 32, 0.25)"
                : "rgba(211, 47, 47, 0.25)",
          },
          "& .MuiLinearProgress-bar": {
            backgroundColor:
              progress >= 75
                ? "#33CC99"
                : progress < 75 && progress > 30
                ? "#FFB020"
                : "#D32F2F",
          },
        }}
        variant="determinate"
        value={progress}
      />
    </Box>
  );
};
export default MobileScoreMeter;
