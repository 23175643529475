import { Avatar, Typography } from "@mui/material";
interface INumber {
	text: string;
}
const NumberRounded: React.FC<INumber> = ({ text }) => {
	return (
		<Avatar sx={{ backgroundColor: "#c1d3f7" }}>
			<Typography variant="body1">{text}</Typography>
		</Avatar>
	);
};

export default NumberRounded;
