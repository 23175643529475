import { useCallback, useState } from "react";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
} from "recharts";
import { Row } from "../../../utils/styledComponents";
import { Divider, Typography } from "@mui/material";
interface IDataItem {
  name: string;
  value: number;
}
interface LPPieChartProps {
  jettons: IDataItem[];
}
const data01: IDataItem[] = [
  {
    name: "TON",
    value: 50,
  },
  {
    name: "USDC",
    value: 100,
  },
];
const renderLegend = (props: any) => {
  const { payload } = props;

  return (
    <Row gap={2} justify="center" sx={{ paddingBottom: "1rem" }}>
      {payload.map((entry: any, index: number) => {
        return (
          <Row key={index} gap={0.5}>
            <Divider
              flexItem
              orientation="vertical"
              sx={{
                background: entry.color,
                width: "5px",
                borderRadius: "25px",
              }}
            />
            <Typography variant="body2">{entry.value}</Typography>
          </Row>
        );
      })}
    </Row>
  );
};
const renderActiveShape = (props: any): JSX.Element => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
  } = props;
  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={0}
        textAnchor="middle"
        style={{ fontSize: "1rem", fontFamily: "Fredoka, sans-serif" }}
        fill={"#252525"}
      >
        {payload.name}
      </text>
      <text
        x={cx}
        y={cy}
        dy={16}
        textAnchor="middle"
        fill={"#252525"}
        style={{ fontSize: "0.8rem", fontFamily: "Fredoka, sans-serif" }}
      >
        {(percent * 100).toFixed(2)}%
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius + 1}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        cornerRadius={15}
      />
    </g>
  );
};

const LPPieChart: React.FC<LPPieChartProps> = ({ jettons }) => {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
  const onPieEnter = useCallback(
    (data: IDataItem, index: number) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  return (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={jettons}
          innerRadius={50}
          outerRadius={80}
          fill={"#2269F8"}
          dataKey="value"
          cornerRadius={10}
          onMouseEnter={onPieEnter}
          onMouseLeave={() => setActiveIndex(undefined)}
        >
          {data01.map((entry, index) => (
            <Cell
              style={{ outline: "none", border: "none" }}
              key={`cell-${index}`}
              fill={index === 0 ? "#940686" : "#2269F8"}
            />
          ))}
        </Pie>
        <Legend content={renderLegend} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default LPPieChart;
