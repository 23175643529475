import { SvgIcon } from "@mui/material";

const WalletIcon = ({ width, height }) => {
	return (
		<SvgIcon sx={{ width: width, height: height }}>
			<svg
				width="29"
				height="25"
				viewBox="0 0 29 25"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M26.1364 4.54545H3.40909C3.10771 4.54545 2.81867 4.42573 2.60556 4.21262C2.39245 3.99951 2.27273 3.71047 2.27273 3.40909C2.27273 3.10771 2.39245 2.81867 2.60556 2.60556C2.81867 2.39245 3.10771 2.27273 3.40909 2.27273H22.7273C23.0287 2.27273 23.3177 2.153 23.5308 1.93989C23.7439 1.72678 23.8636 1.43775 23.8636 1.13636C23.8636 0.834981 23.7439 0.545943 23.5308 0.332833C23.3177 0.119724 23.0287 0 22.7273 0H3.40909C2.50494 0 1.63783 0.359171 0.9985 0.9985C0.359171 1.63783 0 2.50494 0 3.40909V21.5909C0 22.4951 0.359171 23.3622 0.9985 24.0015C1.63783 24.6408 2.50494 25 3.40909 25H26.1364C26.7391 25 27.3172 24.7606 27.7434 24.3343C28.1696 23.9081 28.4091 23.33 28.4091 22.7273V6.81818C28.4091 6.21542 28.1696 5.63734 27.7434 5.21112C27.3172 4.7849 26.7391 4.54545 26.1364 4.54545ZM21.0227 15.9091C20.6856 15.9091 20.356 15.8091 20.0757 15.6218C19.7954 15.4345 19.5769 15.1683 19.4479 14.8568C19.3189 14.5454 19.2852 14.2027 19.3509 13.872C19.4167 13.5414 19.579 13.2376 19.8174 12.9993C20.0558 12.7609 20.3595 12.5985 20.6902 12.5328C21.0208 12.467 21.3636 12.5007 21.675 12.6298C21.9865 12.7588 22.2527 12.9772 22.44 13.2576C22.6273 13.5379 22.7273 13.8674 22.7273 14.2045C22.7273 14.6566 22.5477 15.0902 22.228 15.4098C21.9084 15.7295 21.4748 15.9091 21.0227 15.9091Z"
					fill="#2269F8"
				/>
			</svg>
		</SvgIcon>
	);
};

export default WalletIcon;
