import { Typography, useMediaQuery } from "@mui/material";
import { Col, Row } from "../../utils/styledComponents";
import { IWalletTile } from "../../types/interface";

const WalletTile: React.FC<IWalletTile> = ({ label, text, icon }) => {
  const mobile = useMediaQuery("(max-width:600px)");
  return (
    <Row
      sx={{
        backgroundColor: "background.paper",
        padding: "1rem",
        borderRadius: "25px",
        boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
        // height: "6rem",
        gap: 2,
        // width: "100%",
      }}
      justify="space-between"
      align="center"
    >
      {!mobile ? (
        <Col align="flex-start">
          <Typography variant="body1">{label}</Typography>
          <Typography variant="h1">{text}</Typography>
        </Col>
      ) : (
        <Col align="flex-start" sx={{ width: "100%", height: "100%" }}>
          <Row justify="space-between" align="center" sx={{ width: "100%" }}>
            <Typography variant="caption">{label}</Typography>
            {icon}
          </Row>
          <Typography variant="h1">{text}</Typography>
        </Col>
      )}
      {!mobile && icon}
    </Row>
  );
};

export default WalletTile;
