import { Button, InputAdornment, TextField } from "@mui/material";
import { ICustomInput } from "../../types/interface";

const CustomInput: React.FC<ICustomInput> = ({
  token,
  handleChangePair,
  jettonsInput,
  jettonsAvailable,
  name,
  fetching,
}) => {
  return (
    <TextField
      id="outlined-number"
      label={token?.symbol}
      type="number"
      InputLabelProps={{
        shrink: true,
      }}
      sx={{
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
          {
            display: "none",
          },
        "& input[type=number]": {
          MozAppearance: "textfield",
        },
      }}
      name={name}
      value={jettonsInput === 0 ? "" : jettonsInput}
      // defaultValue={0}
      placeholder="0"
      onChange={(event) => {
        console.log(event.target.value);
        console.log("jettons available", jettonsAvailable);
        const jettonAmount = event.target.value;
        const name = event.target.name;
        if (parseInt(jettonAmount) > jettonsAvailable) {
          console.log("39", event.target.value);
          handleChangePair({ amount: jettonsAvailable, name: name });
        } else {
          console.log("42", event.target.value);
          handleChangePair({ amount: parseInt(jettonAmount), name: name });
        }
      }}
      InputProps={{
        inputProps: { min: 0, max: jettonsAvailable },
        endAdornment: (
          <InputAdornment position={"end"}>
            <Button
              onClick={() => {
                handleChangePair({ amount: jettonsAvailable, name: name });
              }}
            >
              Max
            </Button>
          </InputAdornment>
        ),
      }}
      helperText={
        fetching ? `Fetching balance...` : `Available: ${jettonsAvailable}`
      }
    />
  );
};

export default CustomInput;
