import { api } from "../utils/apiConfig";
import { URL_POOLS } from "../utils/url";
const GetSinglePool = async (id?: string) => {
	return api
		.get(`${URL_POOLS}/${id}`)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			return err;
		});
};

export default GetSinglePool;
