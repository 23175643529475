import { useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
// import { sendWithdrawLP } from "../../calls/transactionConstructors";
import { sendWithdrawLP } from "../../../contracts/transactionConstructors";
import ButtonRectangular from "../../CommonComponents/ButtonRectangular";
import { IWithdrawLP } from "../../../types/interface";
import { useContext, useState } from "react";
import { UserWalletsContext } from "../../../page/PoolPage";
import { AnalyticsContext } from "../../../providers/AnalyticsProvider";
import { logEvent } from "@firebase/analytics";

const WithdrawLPButton: React.FC<IWithdrawLP> = ({
	userAddress,
	token1,
	token2,
	withdraw,
	amount,
	handleModal,
}) => {
	const [loading, setLoading] = useState(false);
	const [tonConnectUI] = useTonConnectUI();
	const walletContext = useContext(UserWalletsContext);
	const analyticsContext = useContext(AnalyticsContext);
	const wallet = useTonWallet();

	const onClick = async () => {
		setLoading(true);
		if (!walletContext) {
			console.error("Client context is not available");
			setLoading(false);
			return;
		}
		try {
			const transaction = await sendWithdrawLP(
				walletContext?.userWallets.lp?.address as string,
				userAddress,
				withdraw,
				token1,
				token2,
				amount
			);
			if (transaction) {
				const result = await tonConnectUI.sendTransaction(transaction);
				if (result) {
					console.log(result);

					handleModal(result.boc);
					if (analyticsContext) {
						logEvent(analyticsContext.analytics, "contract", {
							contract: "WITHDRAW_LP",
							status: "Success",
							errMessage: "none",
						});
					}
				}

				return result;
			}
		} catch (err) {
			const error = err as Error;
			throw new Error(error.message);
		} finally {
			setLoading(false);
		}
	};
	return (
		<ButtonRectangular
			onClick={onClick}
			disabled={loading}
			variant="outlined"
			text={loading ? "Pending..." : "Convert to token pair"}
		/>
	);
};

export default WithdrawLPButton;
