import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import { useLocation, useLoaderData } from "react-router-dom";

// Assuming IPool is defined somewhere
import { IAccount, IPool } from "../types/interface";
import useAccount from "../hooks/useAccount";
import GetSinglePool from "../api/GetSinglePool";

interface PoolDataContextType {
  poolData: IPool | null;
  loading: boolean;
  setPoolData: Dispatch<SetStateAction<IPool | null>>;
  account: IAccount | null;
  fetchOnDemand: () => void;
}

// Create a context
export const PoolDataContext = createContext<PoolDataContextType | null>(null);

interface PoolDataProviderProps {
  children: ReactNode;
}
export const PoolDataProvider: React.FC<PoolDataProviderProps> = ({
  children,
}) => {
  const [poolData, setPoolData] = useState<IPool | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { account, fetchAccount } = useAccount();
  const data = useLoaderData();
  const location = useLocation();
  const fetchOnDemand = async () => {
    const payload = location.state?.payload as IPool | undefined;
    if (payload) {
      try {
        const response = await GetSinglePool(payload.id);
        if (response) {
          setPoolData(response.data);
        }
      } catch (err) {
        console.log(err);
      }
    } else if (data && Object.keys(data).length > 0) {
      try {
        const pool = data as IPool;
        const response = await GetSinglePool(pool.id);
        if (response) {
          setPoolData(response.data);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  useEffect(() => {
    console.log("fetch pool triggered");
    const payload = location.state?.payload as IPool | undefined;
    setLoading(true);
    if (payload) {
      setPoolData(payload);
      setLoading(false);

      fetchAccount(payload.lpToken.address as string);
    } else if (data && Object.keys(data).length > 0) {
      setPoolData(data as IPool);
      setLoading(false);
      const pool = data as IPool;
      fetchAccount(pool.lpToken.address as string);
    } else {
      setLoading(false);
      setPoolData(null);
    }
  }, [location.state]);

  return (
    <PoolDataContext.Provider
      value={{ poolData, loading, setPoolData, account, fetchOnDemand }}
    >
      {children}
    </PoolDataContext.Provider>
  );
};
