import { SvgIcon } from "@mui/material";

const TonSymbol = ({ width, height }) => {
	return (
		<SvgIcon sx={{ width: width, height: height }}>
			<svg
				width="25"
				height="25"
				viewBox="0 0 25 25"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_682_498)">
					<path
						d="M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59643 19.4036 0 12.5 0C5.59643 0 0 5.59643 0 12.5C0 19.4036 5.59643 25 12.5 25Z"
						fill="#0098EA"
					/>
					<path
						d="M16.768 6.97656H8.23152C6.66196 6.97656 5.66714 8.66964 6.45679 10.0383L11.7252 19.17C12.069 19.7662 12.9305 19.7662 13.2743 19.17L18.5438 10.0383C19.3324 8.67183 18.3375 6.97656 16.7691 6.97656H16.768ZM11.7209 16.4315L10.5735 14.2109L7.80504 9.25946C7.62241 8.94254 7.84799 8.53643 8.23045 8.53643H11.7198V16.4326L11.7209 16.4315ZM17.1923 9.25839L14.4249 14.212L13.2775 16.4315V8.53536H16.7669C17.1494 8.53536 17.375 8.94147 17.1923 9.25839Z"
						fill="white"
					/>
				</g>
				<defs>
					<clipPath id="clip0_682_498">
						<rect width="25" height="25" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</SvgIcon>
	);
};

export default TonSymbol;
