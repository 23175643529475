import { Grid, Skeleton, Typography } from "@mui/material";
import { Col, Row } from "../utils/styledComponents";
import HorizontalTile from "../components/CommonComponents/HorizontalTile";
import TonSymbol from "../assets/TonSymbol";
import { IPool } from "../types/interface";
import WifiOffRoundedIcon from "@mui/icons-material/WifiOffRounded";
import { useContext, useEffect, useState } from "react";
import { JettonWalletContext } from "../providers/JettonWalletProvider";
import PoolsList from "../components/DashboardComponents/PoolsList";
import AdBanner from "../assets/AdBanner.webp";
import AdBannerMobile from "../assets/AdBannerMobile.webp";
import ButtonRectangular from "../components/CommonComponents/ButtonRectangular";
import DocsImage from "../assets/docs.webp";
import usePools from "../hooks/useGetPools";
const Dashboard = () => {
  const { loading, error, pools } = usePools();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const walletContext = useContext(JettonWalletContext);

  const countTVL = () => {
    return pools?.reduce((acc: number, obj: IPool) => acc + (obj.tvl || 0), 0);
  };

  if (error) {
    return (
      <Grid container columnSpacing={0} spacing={0}>
        <Col
          justify="center"
          align="center"
          sx={{ height: "80vh", width: "100%" }}
        >
          <WifiOffRoundedIcon
            sx={{ fontSize: "5rem", color: "primary.main" }}
          />
          <Typography variant="h1">Unable to fetch data</Typography>
          <Typography variant="body1">Try again later</Typography>
        </Col>
      </Grid>
    );
  } else {
    return (
      <Grid container columnSpacing={0} spacing={2}>
        <Grid item container xs={12}>
          <Col align="center" sx={{ width: "100%" }}>
            {isMobile ? (
              <img
                onClick={() =>
                  window.open("https://zealy.io/c/tonkfinance/questboard")
                }
                src={AdBannerMobile}
                alt="ad-banner-tasks"
                width="100%"
                style={{
                  borderRadius: "25px",
                  boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
                  cursor: "pointer",
                  maxWidth: "393px",
                }}
              />
            ) : (
              <img
                onClick={() =>
                  window.open("https://zealy.io/c/tonkfinance/questboard")
                }
                src={AdBanner}
                alt="ad-banner-tasks"
                width="100%"
                style={{
                  borderRadius: "25px",
                  boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
                  cursor: "pointer",
                }}
              />
            )}
          </Col>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Row justify="space-between" align="center" sx={{ flexWrap: "wrap" }}>
            <Typography variant="h1">
              <span style={{ fontWeight: "bold" }}>Farm</span> with us!
            </Typography>
            <Row gap={4} align="center" justify="space-between">
              {loading ? (
                <Skeleton
                  width="15rem"
                  height="2rem"
                  variant="rounded"
                  sx={{
                    backgroundColor: "background.paper",
                    boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
                  }}
                />
              ) : (
                <HorizontalTile
                  label={isMobile ? "TVL" : "Total value locked:"}
                  text={`$ ${countTVL()
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`}
                />
              )}
              {loading ? (
                <Skeleton
                  width="15rem"
                  height="2rem"
                  variant="rounded"
                  sx={{
                    backgroundColor: "background.paper",
                    boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
                  }}
                />
              ) : (
                <HorizontalTile
                  label={isMobile ? "Chains" : "Chains supported:"}
                  text={<TonSymbol height="50" width="50" />}
                />
              )}
            </Row>
          </Row>
        </Grid>

        <Grid item sm={12}>
          <PoolsList rows={pools} loading={loading} />
        </Grid>

        <Grid item sm={12} md={8}>
          <Col>
            <Typography
              variant="h1"
              sx={{
                paddingTop: "2rem",
                paddingBottom: "1rem",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              What are Vaults?
            </Typography>
            <Typography
              variant="body1"
              sx={{
                padding: "1rem",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              {`In Tonk Finance, a "Vault" is an advanced tool designed to optimize cryptocurrency investments. Vaults automate the process of earning yields by executing pre-set strategies that may include lending, staking, liquidity provision, or employing various other yield-generating activities. Users deposit their assets into a Vault, and the underlying protocol manages those assets according to a strategy that aims to maximize returns while mitigating risks. This process involves dynamically shifting assets between different DeFi platforms or strategies in response to changing market conditions. Vaults stand out for their efficiency and convenience, offering users a hands-off approach to participating in complex DeFi yield farming opportunities. By pooling resources, they also provide access to high-yield strategies that might be out of reach for individual investors.`}
            </Typography>
          </Col>
          <Col>
            <Typography
              variant="h1"
              sx={{
                paddingTop: "2rem",
                paddingBottom: "1rem",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              What is Yield Aggregator?
            </Typography>
            <Typography
              variant="body1"
              sx={{
                padding: "1rem",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              {`Yield aggregators play a crucial role in farming by automatically reallocating assets across various strategies to secure the best returns, simplifying the investment process. Vault represent a key innovation in DeFi, offering a new avenue for earning passive income while contributing to the liquidity and functionality of the crypto market. Investors are advised to approach with caution, balancing the potential rewards against the inherent risks.`}
            </Typography>
          </Col>
        </Grid>
        <Grid item sm={12} md={4}>
          <Col
            sx={{
              height: "100%",
              backgroundColor: "background.paper",
              boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
              padding: "2rem",
              borderRadius: "25px",
            }}
            justify="space-evenly"
          >
            <Col>
              <img
                src={DocsImage}
                alt="tonk-docs"
                width="100%"
                style={{ borderRadius: "25px" }}
              />
            </Col>
            <Col>
              <Typography variant="h2" sx={{ fontWeight: "600" }}>
                Learn about us.
              </Typography>
              <Typography variant="body1">Invest smarter.</Typography>
            </Col>

            <ButtonRectangular
              text="Explore"
              onClick={() =>
                window.open("https://docs.tonk.finance/tonk-finance")
              }
              disabled={false}
            />
          </Col>
        </Grid>
      </Grid>
    );
  }
};

export default Dashboard;
