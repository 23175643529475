import { ReactNode, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { IPool } from "../types/interface";
import { SelectChangeEvent } from "@mui/material";
import { JettonWalletContext } from "../providers/JettonWalletProvider";
import { Address } from "ton-core";

const usePoolsFilter = (
	rows: IPool[] | null,
	context: JettonWalletContext | null
) => {
	const [poolsFilter, setPoolsFilter] = useState<string>("all");
	const [filteredArrPools, setFilteredArrPools] = useState<IPool[] | null>([]);
	const handleFilterPools = (
		event: SyntheticEvent<Element, Event>,
		value: string
	) => {
		setPoolsFilter(value);
	};

	useEffect(() => {
		const balance =
			context?.jettonWallets?.filter(
				(entry) => parseFloat(entry.balance) > 0
			) || [];
		const myPools = rows?.filter((row) =>
			balance.some(
				(e) =>
					e.jetton === Address.parse(row.address).toRawString().toUpperCase()
			)
		);

		if (poolsFilter !== "all") {
			setFilteredArrPools(myPools ? myPools : []);
		} else {
			setFilteredArrPools(rows);
		}
	}, [rows, poolsFilter]);
	return { poolsFilter, handleFilterPools, filteredArrPools };
};
export default usePoolsFilter;
export const useMobilePoolsFilter = (
	rows: IPool[] | null,
	context: JettonWalletContext | null
) => {
	const [poolsFilter, setPoolsFilter] = useState("all");
	const handleFilterPools = (
		event: SelectChangeEvent<string>,
		child: ReactNode
	) => {
		setPoolsFilter(event.target.value);
	};
	const filteredArrPools = useMemo(() => {
		const balance =
			context?.jettonWallets?.filter(
				(entry) => parseFloat(entry.balance) > 0
			) || [];
		const myPools = rows?.filter((row) =>
			balance.some(
				(e) =>
					e.jetton === Address.parse(row.address).toRawString().toUpperCase()
			)
		);

		if (poolsFilter !== "all") {
			return myPools || [];
		} else {
			return rows;
		}
	}, [rows, poolsFilter]);
	return { poolsFilter, handleFilterPools, filteredArrPools };
};
