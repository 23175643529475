import { useEffect, useState } from "react";
import { IPool, IPrice } from "../types/interface";
interface IDataItem {
	name: string;
	value: number;
	valuePerToken: number;
	icon?: string;
}
const useCalculateLP = (
	balance: string | undefined,
	pool: IPool | undefined | null,
	price: IPrice[] | undefined | null
) => {
	const [jettons, setJettons] = useState<IDataItem[]>([]);
	const [lp, setLP] = useState<IDataItem>({
		name: "",
		value: 0,
		valuePerToken: 0,
		icon: "",
	});

	const calculateLP = () => {
		if (!pool || !balance || !price) return;
		if (pool.tvl) {
			// const lpAmount = parseFloat(fromNano(balance));
			const priceToken1 = price.find(
				(entry) => entry.symbol === pool.token1.symbol
			);
			const priceToken2 = price.find(
				(entry) => entry.symbol === pool.token2.symbol
			);
			const valuePerLPToken = pool.tvl + 2753 * pool.tvl;
			const valuePerToken = valuePerLPToken / 2;
			if (
				priceToken1 &&
				priceToken1.fiatValue &&
				priceToken2 &&
				priceToken2?.fiatValue
			) {
				const amountToken1 = valuePerToken / priceToken1?.fiatValue;
				const amountToken2 = valuePerToken / priceToken2?.fiatValue;

				setJettons([
					{
						value: amountToken1,
						name: priceToken1.symbol,
						valuePerToken: amountToken1 * priceToken1?.fiatValue,
						icon: pool.token1.icon,
					},
					{
						value: amountToken2,
						name: priceToken2.symbol,
						valuePerToken: amountToken2 * priceToken2?.fiatValue,
						icon: pool.token2.icon,
					},
				]);
				setLP({
					name: "LP",
					value: amountToken1 + amountToken2,
					valuePerToken:
						amountToken1 * priceToken1?.fiatValue +
						amountToken2 * priceToken2?.fiatValue,

					icon: pool.lpToken.icon,
				});
			}
		}
	};
	useEffect(() => {
		calculateLP();
	}, [balance, pool, price]);
	return { jettons, calculateLP, lp };
};

export default useCalculateLP;
