import { ReactNode, SyntheticEvent, useMemo, useState } from "react";
import { IPool } from "../types/interface";
import { SelectChangeEvent } from "@mui/material";

const useCategoryFilter = (rows: IPool[] | null, mobile: boolean) => {
	const [categoryFilter, setCategoryFilter] = useState<string>("all");
	const handleFilterCategory = (
		event: SyntheticEvent<Element, Event>,
		value: string
	) => {
		setCategoryFilter(value);
	};
	const handleMobileFilterCategory = (
		event: SelectChangeEvent<string>,
		child: ReactNode
	) => {
		setCategoryFilter(event.target.value);
	};
	const filteredArr = useMemo(() => {
		if (categoryFilter !== "all") {
			return (
				rows?.filter((entry) => {
					return entry.category?.find((e) => e === categoryFilter);
				}) || []
			);
		} else {
			return rows;
		}
	}, [rows, categoryFilter]);
	return {
		categoryFilter,
		handleFilterCategory,
		filteredArr,
		handleMobileFilterCategory,
	};
};
export default useCategoryFilter;
export const useMobileCategoryFilter = (rows: IPool[] | null) => {
	const [categoryFilter, setCategoryFilter] = useState<string>("all");
	const handleFilterCategory = (
		event: SelectChangeEvent<string>,
		child: ReactNode
	) => {
		setCategoryFilter(event.target.value);
	};
	const filteredArr = useMemo(() => {
		if (categoryFilter !== "all") {
			return (
				rows?.filter((entry) => {
					return entry.category?.find((e) => e === categoryFilter);
				}) || []
			);
		} else {
			return rows;
		}
	}, [rows, categoryFilter]);
	return { categoryFilter, handleFilterCategory, filteredArr };
};
