import { Button } from "@mui/material";
import { IButtonProps } from "../../types/interface";

const ButtonRound: React.FC<IButtonProps> = ({ text, onClick, disabled }) => {
  return (
    <Button
      sx={{
        borderRadius: "100vw",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        backgroundColor: "primary.main",
        textTransform: "none",
      }}
      variant="contained"
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export default ButtonRound;
