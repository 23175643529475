/* eslint-disable no-shadow */
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";
import { Row } from "../../../../utils/styledComponents";
import { Divider, Typography } from "@mui/material";
interface IDataItem {
  name: string;
  value: number;
  color: string;
}
interface INeedle {
  value: number;
}
const RADIAN = Math.PI / 180;
const data: IDataItem[] = [
  { name: "High", value: 30, color: "#D32F2F" },
  { name: "Medium", value: 40, color: "#FFB020" },
  { name: "Low", value: 30, color: "#33CC99" },
];
const cx = 190;
const cy = 200;
const iR = 50;
const oR = 100;
const needle = (
  value: number,
  data: IDataItem[],
  cx: number,
  cy: number,
  iR: number,
  oR: number,
  color: string
): JSX.Element[] => {
  let total = 0;
  data.forEach((v) => {
    total += v.value;
  });
  const ang = 180.0 * (1 - value / total);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx + 5;
  const y0 = cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return [
    <circle
      key={`circle-${ang + 1}`}
      cx={x0}
      cy={y0}
      r={r}
      fill={"#2269F8"}
      stroke="none"
    />,
    <path
      key={`path-${ang + 1}`}
      d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
      stroke="#none"
      fill={"#2269F8"}
    />,
    <text
      key={`text-${ang + 1}`}
      x={cx * 1.04}
      y={cy}
      dy={30}
      textAnchor="middle"
      fill={"#252525"}
      style={{ fontSize: "1rem", fontFamily: "Fredoka, sans-serif" }}
    >
      risk-o-meter
    </text>,
  ];
};
const renderLegend = (props: any) => {
  const { payload } = props;

  return (
    <Row gap={2} justify="center" sx={{ paddingBottom: "1rem" }}>
      {payload.map((entry: any, index: number) => {
        return (
          <Row key={index} gap={0.5}>
            <Divider
              flexItem
              orientation="vertical"
              sx={{
                background: entry.color,
                width: "5px",
                borderRadius: "25px",
              }}
            />
            <Typography variant="body2">{entry.value}</Typography>
          </Row>
        );
      })}
    </Row>
  );
};
const SafetyScoreMeter: React.FC<INeedle> = ({ value }) => {
  return (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          fill="#8884d8"
          stroke="none"
          cornerRadius={5}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        {needle(value, data, cx, cy, iR, oR, "#d0d000")}
        <Legend content={renderLegend} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default SafetyScoreMeter;
