import { api } from "../utils/apiConfig";
import { URL_POOLS } from "../utils/url";

interface ApiResponse {
	data: any;
	status: number;
}
const GetPools = async (): Promise<ApiResponse> => {

	return api
		.get(URL_POOLS)
		.then((res) => {
			console.log(res);
			return res;
		})
		.catch((err) => {
			console.log(err);
			return Promise.reject(err);
		});
};

export default GetPools;

// const GetPools = async () => {
// 	const mock = new MockAdapter(api);
// 	const response = mock.onGet(URL_POOLS).reply(function (config) {
// 		return [
// 			200,
// 			{
// 				data: [
// 					{
// 						id: "FUy9Dqoasw4PDkrpWzZo",
// 						icon: "https://github-production-user-asset-6210df.s3.amazonaws.com/121061747/280542634-efede7f8-4bdd-4422-a170-4d8a41316c72.png",
// 						type: "LP",
// 						category: ["featured", "stablecoins"],
// 						dailyApy: 0,
// 						token1: {
// 							symbol: "tkTONK",
// 							address: "EQCX-SoQLXx59ZtKjlldK59kzPRhSF7QH7kqk6oBuuapvNXy",
// 							name: "TEST TONK",
// 							icon: "https://tonk.imgix.net/testTonk.png?w=100&h=100",
// 						},
// 						token2: {
// 							symbol: "tkUSDC",
// 							address: "EQD57ZD9cAL20dHe9o4WdFctWZK-v6BjN7D_ntn0DGu0LfUl",
// 							name: "TONK USDC",
// 							icon: "https://tonk.imgix.net/testUSDC.png?w=100&h=100",
// 						},
// 						lpToken: {
// 							symbol: "tkTONK/tkUSDC",
// 							name: "LP tkTONK/tkUSDC Test Token",
// 							address: "EQCpXPDSzEytTt0ycZbBHFkX85laomejJShrErp_Ilnne_7G",
// 						},
// 						address: "EQDY6nAeZ21bfcBETudifxwSx-791Hzqpf_FmY7Yc2mKHvnh",
// 						dailyApr: 0.1,
// 						apr: 6.349841269841269,
// 						apy: 6.5551909684419485,
// 						tvl: 945,
// 					},
// 					{
// 						id: "I1kndrttinPVjpqC5sPP",
// 						icon: "https://github-production-user-asset-6210df.s3.amazonaws.com/121061747/280542631-235f9d7c-5d44-4090-aae6-542b3741495f.png",
// 						type: "LP",
// 						category: ["featured", "ton_pair"],
// 						token1: {
// 							symbol: "tkTONK",
// 							address: "EQCX-SoQLXx59ZtKjlldK59kzPRhSF7QH7kqk6oBuuapvNXy",
// 							name: "TEST TONK",
// 							icon: "https://tonk.imgix.net/testTonk.png?w=100&h=100",
// 						},
// 						token2: {
// 							symbol: "tkWTON",
// 							address: "EQDXPw9-iVsbgpJxLHx5yja603CYxAmqYx56YaIdRK3u7d5e",
// 							name: "TONK WTON",
// 							icon: "https://tonk.imgix.net/testTon.png?w=100&h=100",
// 						},
// 						lpToken: {
// 							symbol: "tkTONK/tkTON",
// 							name: "LP tkTONK/tkTON Test Token",
// 							address: "EQCoVp3Euuce7fU_9Iur-smOniZM0Zc0W_PpAItqQYz8DIvF",
// 						},
// 						address: "EQBgaWm76AplcGfdcXBxFBZyIyZTx-pV_oRI6YedLAqZEjQ7",
// 						dailyApr: 0.1,
// 						dailyApy: 0.1,
// 						apr: 112.03472222222221,
// 						apy: 206.06622398783557,
// 						tvl: 72,
// 					},
// 					{
// 						id: "Xgcln1A8JHZIo1Gc2X5t",
// 						lpToken: {
// 							symbol: "tkUSDC/tkUSDT",
// 							address: "EQBLoptr-EhE1c4AqK-HDGN6ak4FiHy5tdW4SVi-9-8bWlaQ",
// 							name: "LP tkUSDC/tkUSDT Test Token",
// 						},
// 						icon: "https://github-production-user-asset-6210df.s3.amazonaws.com/121061747/280542638-46234cbc-9552-4b4d-bcc2-b41459ae27a4.png",
// 						type: "LP",
// 						category: ["stablecoins"],
// 						token1: {
// 							symbol: "tkUSDC",
// 							address: "EQD57ZD9cAL20dHe9o4WdFctWZK-v6BjN7D_ntn0DGu0LfUl",
// 							name: "TONK USDC",
// 							icon: "https://tonk.imgix.net/testUSDC.png?w=100&h=100",
// 						},
// 						token2: {
// 							symbol: "tkUSDT",
// 							address: "EQBaxU1KiJKY-8ASEQ3K7vPyCrIWN1v8P8OUXj-yfnlkCd1D",
// 							name: "TONK USDT",
// 							icon: "https://tonk.imgix.net/testUSDT.png?w=100&h=100",
// 						},
// 						address: "EQA7qYjRAxYgsyUlesbXLBr2GhZa4B6WvFhWgX01RBjpCuAS",
// 						dailyApr: 0.1,
// 						dailyApy: 0.1,
// 						apr: 6.139696485623004,
// 						apy: 6.33154412931507,
// 						tvl: 626,
// 					},
// 					{
// 						id: "aksARJ0OEdPOkKSDSS0x",
// 						icon: "https://github-production-user-asset-6210df.s3.amazonaws.com/121061747/280542633-89dead6f-5390-4077-b070-0bb9b5b8db27.png",
// 						type: "LP",
// 						category: ["stablecoins", "ton_pair"],
// 						tvl: 0,
// 						token1: {
// 							symbol: "tkUSDC",
// 							address: "EQD57ZD9cAL20dHe9o4WdFctWZK-v6BjN7D_ntn0DGu0LfUl",
// 							name: "TONK USDC",
// 							icon: "https://tonk.imgix.net/testUSDC.png?w=100&h=100",
// 						},
// 						token2: {
// 							symbol: "tkWTON",
// 							address: "EQDXPw9-iVsbgpJxLHx5yja603CYxAmqYx56YaIdRK3u7d5e",
// 							name: "TONK WTON",
// 							icon: "https://tonk.imgix.net/testTon.png?w=100&h=100",
// 						},
// 						lpToken: {
// 							symbol: "tkWTON/tkUSDC",
// 							name: "USDC-WTON Vault Token",
// 							address: "EQB0rZWpk7cuLanfWl1--rCEib5Avc0YfbPTpV60sEzz3mQm",
// 						},
// 						address: "EQA-IMhBFZYftPl98iQ6yTcTizqsF0gw_z1Sv2keObA0i7ok",
// 						dailyApr: 0,
// 						dailyApy: 0,
// 						apr: 60005.99999999999,
// 						apy: 1.334841028601656e156,
// 					},
// 					{
// 						id: "lZnZW1OoLxG9ThJ2tH6l",
// 						lpToken: {
// 							symbol: "tkWTON/tkUSDT",
// 							address: "EQBBZhpHF7QKnANL2Aq1zPPmMT6krmJLeQXXVvbehTyWDrqs",
// 							name: "LP tkWTON/tkUSDT Test Token",
// 						},
// 						icon: "https://github-production-user-asset-6210df.s3.amazonaws.com/121061747/280542637-9b600c13-1692-46e0-9371-0453696ec299.png",
// 						type: "LP",
// 						category: ["stablecoins", "ton_pair"],
// 						token1: {
// 							symbol: "tkWTON",
// 							address: "EQDXPw9-iVsbgpJxLHx5yja603CYxAmqYx56YaIdRK3u7d5e",
// 							name: "TONK WTON",
// 							icon: "https://tonk.imgix.net/testTon.png?w=100&h=100",
// 						},
// 						token2: {
// 							symbol: "tkUSDT",
// 							address: "EQBaxU1KiJKY-8ASEQ3K7vPyCrIWN1v8P8OUXj-yfnlkCd1D",
// 							name: "TONK USDT",
// 							icon: "https://tonk.imgix.net/testUSDT.png?w=100&h=100",
// 						},
// 						address: "EQA_VAfZpS134u3IJmRMKmMCW13wgFH9jsnBJIVw2_pc5klY",
// 						dailyApr: 0,
// 						dailyApy: 0,
// 						apr: 45.456538461538464,
// 						apy: 57.5042986680228,
// 						tvl: 130,
// 					},
// 				],
// 			},
// 		];
// 	});
// 	console.log("response", response);
// 	return api
// 		.get(URL_POOLS)
// 		.then((res) => {
// 			console.log(res);
// 			return res.data;
// 		})
// 		.catch((err) => {
// 			console.error(err);
// 			return err;
// 		});
// };
// export default GetPools;
