import { api } from "../utils/apiConfig";

const GetAccount = async (vaultAddress: string) => {
	return api
		.get(`https://testnet.toncenter.com/api/v3/account`, {
			params: {
				address: vaultAddress,
				// api_key: process.env.REACT_APP_TONCENTER_API_KEY,
			},
		})
		.then((res) => {
			return res;
		})
		.catch((err) => {
			return err;
		});
};

export default GetAccount;
