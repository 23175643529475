import { initializeApp } from "firebase/app";
import { getAnalytics, Analytics } from "firebase/analytics";
import { ReactNode, createContext } from "react";

interface AnalyticsContextType {
	analytics: Analytics;
}

export const AnalyticsContext = createContext<AnalyticsContextType | undefined>(
	undefined
);

interface IAnalyticsProvider {
	children: ReactNode;
}
export const AnalyticsProvider: React.FC<IAnalyticsProvider> = ({
	children,
}) => {
	const firebaseConfig = {
		apiKey: process.env.REACT_APP_ANALYTICS_APY_KEY,
		authDomain: process.env.REACT_APP_AUTH_DOMAIN,
		projectId: process.env.REACT_APP_PROJECT_ID,
		storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
		messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
		appId: process.env.REACT_APP_APP_ID,
		measurementId: process.env.REACT_APP_MEASUREMENT_ID,
	};
	const app = initializeApp(firebaseConfig);
	const analytics = getAnalytics(app);

	return (
		<AnalyticsContext.Provider value={{ analytics }}>
			{children}
		</AnalyticsContext.Provider>
	);
};
