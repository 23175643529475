import styled from "@emotion/styled";
import {
	AppBar,
	AppBarProps,
	Box,
	IconButton,
	Toolbar,
	Typography,
	useMediaQuery,
} from "@mui/material";
import LogoMain from "../../assets/LogoMain";
import ButtonRound from "../CommonComponents/ButtonRound";
import { TonConnectButton, useTonConnectUI } from "@tonconnect/ui-react";
import { useContext, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import DrawerMenu from "./DrawerMenu";
import { StyledLink } from "../../utils/styledComponents";
import { getTestnetJettons } from "../../contracts/getTestnetJettons";
import { useNavigate } from "react-router-dom";
import { AnalyticsContext } from "../../providers/AnalyticsProvider";
import { logEvent } from "@firebase/analytics";
const StyledAppBar = styled(AppBar)<AppBarProps>(({ theme }) => ({
	background: "transparent",
	boxShadow: "none",
}));

const Header = () => {
	const [tonConnectUI] = useTonConnectUI();
	const [openDrawer, setOpenDrawer] = useState(false);
	const navigate = useNavigate();
	const analyticsContext = useContext(AnalyticsContext);
	const handleOpenDrawer = () => {
		setOpenDrawer(true);
	};
	const handleCloseDrawer = () => {
		setOpenDrawer(false);
	};
	const handleGetJettons = async () => {
		const transaction = getTestnetJettons();
		try {
			const result = await tonConnectUI.sendTransaction(transaction);
			if (result) {
				if (analyticsContext) {
					logEvent(analyticsContext.analytics, "contract", {
						contract: "LP_TO_VAULT",
						status: "Success",
						errMessage: "none",
					});
				}
			}
			return result;
		} catch (err) {
			const error = err as Error;
			throw new Error(error.message);
		}
	};
	const tablet = useMediaQuery("(max-width:1080px)");
	return (
		<StyledAppBar position="static">
			<Toolbar
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					gap: tablet ? 0 : "1rem",
				}}
			>
				<Box style={{ flexGrow: tablet ? 0 : 1 }}>
					{tablet ? (
						<IconButton
							sx={{
								":hover": {
									backgroundColor: "transparent",
								},
							}}
							onClick={() => navigate("/")}
						>
							<LogoMain height="5rem" width="5rem" />
						</IconButton>
					) : (
						<IconButton
							sx={{
								":hover": {
									backgroundColor: "transparent",
								},
							}}
							onClick={() => navigate("/")}
						>
							<LogoMain height="7rem" width="7rem" />
						</IconButton>
					)}
				</Box>
				<Box
					sx={{
						display: tablet ? "none" : "flex",
						flexDirection: "row",
						gap: 6,
						alignItems: "center",
					}}
				>
					<StyledLink
						to="/"
						style={({ isActive, isPending, isTransitioning }) => {
							return {
								fontWeight: isActive ? "600" : "",
								viewTransitionName: isTransitioning ? "slide" : "",
							};
						}}
					>
						<Typography variant="body1">Vaults</Typography>
					</StyledLink>
					<StyledLink
						to="account"
						style={({ isActive, isPending, isTransitioning }) => {
							return {
								fontWeight: isActive ? "600" : "",
								viewTransitionName: isTransitioning ? "slide" : "",
							};
						}}
					>
						<Typography variant="body1">Manage</Typography>
					</StyledLink>
					<StyledLink
						to="https://docs.tonk.finance/tonk-finance/"
						style={({ isActive, isPending, isTransitioning }) => {
							return {
								fontWeight: isActive ? "600" : "",
								viewTransitionName: isTransitioning ? "slide" : "",
							};
						}}
					>
						<Typography variant="body1">Docs</Typography>
					</StyledLink>
					<ButtonRound
						disabled={false}
						text="Get testnet jettons"
						onClick={handleGetJettons}
					/>
				</Box>
				<TonConnectButton style={{ borderRadius: "2px" }} />
				<Box sx={{ flexGrow: 0, display: tablet ? "flex" : "none" }}>
					<IconButton onClick={handleOpenDrawer} sx={{ p: 0 }}>
						<MenuIcon sx={{ fontSize: "2rem" }} />
					</IconButton>
					<DrawerMenu
						open={openDrawer}
						handleClose={handleCloseDrawer}
						onClick={() => {
							handleCloseDrawer();
							handleGetJettons();
						}}
					/>
				</Box>
			</Toolbar>
		</StyledAppBar>
	);
};

export default Header;
