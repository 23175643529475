import { api } from "../utils/apiConfig";

const GetJettonWallets = async (userAddress: string) => {
	return api
		.get(`https://testnet.toncenter.com/api/v3/jetton/wallets`, {
			params: {
				owner_address: userAddress,
				api_key: process.env.REACT_APP_TONCENTER_API_KEY,
			},
		})
		.then((res) => {
			return res;
		})
		.catch((err) => {
			return err;
		});
};

export default GetJettonWallets;
