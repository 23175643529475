import { IPairWrapper } from "../../../types/interface";
import { Col } from "../../../utils/styledComponents";
import CustomInput from "../../CommonComponents/CustomInput";
import DepositPairButton from "./DepositPairButton";
import ButtonRectangular from "../../CommonComponents/ButtonRectangular";
import { useContext } from "react";
import { PoolDataContext } from "../../../providers/PoolDataProvider";
import { useTonWallet } from "@tonconnect/ui-react";

const DepositPairWrapper: React.FC<IPairWrapper> = ({
  handleChangeToken1,
  handleChangeToken2,
  jettonsInput,
  jettonsAvailable,
  userAddress,
  fetching,
  handleModal,
}) => {
  const context = useContext(PoolDataContext);
  const wallet = useTonWallet();
  return (
    <Col gap={2} justify="space-between" sx={{ height: "14rem" }}>
      <Col gap={2}>
        <CustomInput
          token={context?.poolData?.token1}
          handleChangePair={handleChangeToken1}
          jettonsAvailable={jettonsAvailable.token1}
          jettonsInput={jettonsInput.token1}
          name="token1"
          fetching={fetching}
        />
        <CustomInput
          token={context?.poolData?.token2}
          handleChangePair={handleChangeToken2}
          jettonsAvailable={jettonsAvailable.token2}
          jettonsInput={jettonsInput.token2}
          name="token2"
          fetching={fetching}
        />
      </Col>
      {wallet ? (
        jettonsInput.token1 === 0 ||
        isNaN(jettonsInput.token1) ||
        jettonsInput.token2 === 0 ||
        isNaN(jettonsInput.token2) ? (
          <ButtonRectangular
            disabled={true}
            onClick={() => console.error("Enter jettons amount")}
            text="Deposit"
          />
        ) : (
          context?.poolData &&
          userAddress && (
            <DepositPairButton
              lpToken={context.poolData.lpToken.address}
              token1={context.poolData.token1.address}
              token2={context.poolData.token2.address}
              userAddress={userAddress}
              amount0={jettonsInput.token1}
              amount1={jettonsInput.token2}
              jettonsAvailable={jettonsAvailable}
              handleModal={handleModal}
            />
          )
        )
      ) : (
        <ButtonRectangular
          disabled={true}
          onClick={() => console.error("Wallet not connected")}
          text="Wallet not connected"
        />
      )}
    </Col>
  );
};

export default DepositPairWrapper;
