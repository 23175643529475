import { Collapse, Typography } from "@mui/material";
import { Col } from "../../../../utils/styledComponents";
interface IDescription {
  expanded: boolean;
}
const SafetyScoreDescription: React.FC<IDescription> = ({ expanded }) => {
  return (
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <Col sx={{ padding: "1rem" }}>
        <Typography variant="body1" sx={{ textAlign: "left" }}>
          The Safety Score is implemented to help you make informed decisions
          about whether or not to deposit your assets into the vault.
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "left" }}>
          There are many factors you typically need to consider, but the Safety
          Score does this for you. We take various factors into account to
          ensure the information provided to you is as accurate as possible.
          While the Safety Score cannot fully substitute your own analysis, it
          can serve as a guideline to initiate your research.
        </Typography>
      </Col>
    </Collapse>
  );
};

export default SafetyScoreDescription;
