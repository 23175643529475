import { IPrice } from "../types/interface";

export const calculateToken1 = (
	price: IPrice[],
	token1Amount: number,
	token1Name: string,
	token2Name: string
) => {
	if (typeof token1Amount !== "number" || isNaN(token1Amount)) {
		return;
	}
	const token1 = price.find((entry) => entry.symbol === token1Name);
	const token2 = price.find((entry) => entry.symbol === token2Name);
	if (token1 && token2) {
		const token2Calculated =
			(token1Amount * token1?.fiatValue) / token2?.fiatValue;
		return token2Calculated;
	}
};
export const calculateToken2 = (
	price: IPrice[],
	token2Amount: number,
	token1Name: string,
	token2Name: string
) => {
	if (typeof token2Amount !== "number" || isNaN(token2Amount)) {
		return;
	}
	const token1 = price.find((entry) => entry.symbol === token1Name);
	const token2 = price.find((entry) => entry.symbol === token2Name);
	if (token1 && token2) {
		const token1Calculated =
			(token2Amount * token2?.fiatValue) / token1?.fiatValue;
		return token1Calculated;
	}
};
