import { useMemo, useState } from "react";
import { IPool } from "../types/interface";

const useNameFilter = (rows: IPool[] | null) => {
	const [poolName, setPoolname] = useState<string>("");
	const filter = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPoolname(event?.target.value);
	};

	const data = useMemo(() => {
		return (
			rows?.filter((entry) => {
				const symbol1 = entry.token1?.symbol?.toLowerCase() || "";
				const symbol2 = entry.token2?.symbol?.toLowerCase() || "";
				return (
					symbol1.includes(poolName.toLowerCase()) ||
					symbol2.includes(poolName.toLowerCase())
				);
			}) || rows
		);
	}, [rows, poolName]);
	return { poolName, filter, data };
};
export default useNameFilter;
