import { SvgIcon } from "@mui/material";

const VaultIcon = ({ width, height }) => {
	return (
		<SvgIcon sx={{ width: width, height: height }}>
			<svg
				width="25"
				height="25"
				viewBox="0 0 25 25"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_683_1064)">
					<path
						d="M25 0H2.08333C1.5308 0 1.00089 0.219493 0.610194 0.610194C0.219493 1.00089 0 1.5308 0 2.08333V19.7917C0 20.3442 0.219493 20.8741 0.610194 21.2648C1.00089 21.6555 1.5308 21.875 2.08333 21.875H4.16667V23.9583C4.16667 24.2346 4.27641 24.4996 4.47176 24.6949C4.66711 24.8903 4.93207 25 5.20833 25C5.4846 25 5.74955 24.8903 5.9449 24.6949C6.14025 24.4996 6.25 24.2346 6.25 23.9583V21.875H20.8333V23.9583C20.8333 24.2346 20.9431 24.4996 21.1384 24.6949C21.3338 24.8903 21.5987 25 21.875 25C22.1513 25 22.4162 24.8903 22.6116 24.6949C22.8069 24.4996 22.9167 24.2346 22.9167 23.9583V21.875H25C25.5525 21.875 26.0824 21.6555 26.4731 21.2648C26.8638 20.8741 27.0833 20.3442 27.0833 19.7917V2.08333C27.0833 1.5308 26.8638 1.00089 26.4731 0.610194C26.0824 0.219493 25.5525 0 25 0ZM23.9583 12.5H20.194C19.9379 13.6236 19.2769 14.6135 18.3371 15.2807C17.3974 15.9478 16.2448 16.2453 15.0996 16.1164C13.9544 15.9876 12.8968 15.4414 12.1288 14.5821C11.3608 13.7228 10.9362 12.6108 10.9362 11.4583C10.9362 10.3059 11.3608 9.19382 12.1288 8.33457C12.8968 7.47531 13.9544 6.92909 15.0996 6.80023C16.2448 6.67137 17.3974 6.96891 18.3371 7.63602C19.2769 8.30312 19.9379 9.29303 20.194 10.4167H23.9583C24.2346 10.4167 24.4996 10.5264 24.6949 10.7218C24.8903 10.9171 25 11.1821 25 11.4583C25 11.7346 24.8903 11.9996 24.6949 12.1949C24.4996 12.3903 24.2346 12.5 23.9583 12.5ZM18.2292 11.4583C18.2292 11.9734 18.0764 12.4769 17.7903 12.9051C17.5041 13.3334 17.0974 13.6672 16.6216 13.8643C16.1457 14.0614 15.6221 14.1129 15.117 14.0125C14.6118 13.912 14.1478 13.664 13.7836 13.2998C13.4194 12.9356 13.1714 12.4715 13.0709 11.9664C12.9704 11.4612 13.022 10.9376 13.2191 10.4618C13.4162 9.98591 13.7499 9.5792 14.1782 9.29305C14.6065 9.0069 15.1099 8.85417 15.625 8.85417C15.967 8.85417 16.3056 8.92153 16.6216 9.0524C16.9375 9.18327 17.2246 9.37509 17.4664 9.61691C17.7082 9.85873 17.9001 10.1458 18.0309 10.4618C18.1618 10.7777 18.2292 11.1163 18.2292 11.4583Z"
						fill="#2269F8"
					/>
				</g>
				<defs>
					<clipPath id="clip0_683_1064">
						<rect width="25" height="25" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</SvgIcon>
	);
};

export default VaultIcon;
