import styled from "@emotion/styled";
import {
	Box,
	Tooltip,
	TooltipProps,
	Typography,
	tooltipClasses,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: "#3B3B3B",
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#3B3B3B",
	},
}));
interface ITooltip {
	text: string;
}
const CustomTooltip: React.FC<ITooltip> = ({ text }) => {
	return (
		<StyledTooltip
			disableInteractive
			placement="top-end"
			enterTouchDelay={0}
			arrow
			title={
				<Box sx={{ padding: "0.5rem" }}>
					<Typography variant="body2">{text}</Typography>
				</Box>
			}
		>
			<HelpOutlineIcon sx={{ fontSize: "1rem", color: "background.dark" }} />
		</StyledTooltip>
	);
};

export default CustomTooltip;
