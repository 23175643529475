import { useTonConnectUI } from "@tonconnect/ui-react";
import { useContext, useState } from "react";
import { sendDepositLP } from "../../../contracts/transactionConstructors";
import { IDepositLP } from "../../../types/interface";
import ButtonRectangular from "../../CommonComponents/ButtonRectangular";

import { UserWalletsContext } from "../../../page/PoolPage";
import { AnalyticsContext } from "../../../providers/AnalyticsProvider";
import { logEvent } from "@firebase/analytics";

// destination - master contract address (the vault)
// responseDestination - user address
// userJettonWalletContract - address of wallet for particular jetton owned by user

const DepositLPButton: React.FC<IDepositLP> = ({
	userAddress,
	masterAddress,
	vaultAddress,
	inputAmount,
	handleModal,

	// handleTransactionConcluded,
}) => {
	//responseDestination = user address
	const walletContext = useContext(UserWalletsContext);
	const [loading, setLoading] = useState(false);
	const [tonConnectUI] = useTonConnectUI();
	const analyticsContext = useContext(AnalyticsContext);

	const handleChainMessages = async () => {
		setLoading(true);
		// handleTransactionConcluded(false, "", 0);
		if (!walletContext) {
			console.error("Client context is not available");
			setLoading(false);
			return;
		}
		try {
			const transaction = await sendDepositLP(
				userAddress,
				walletContext?.userWallets.lp?.address as string,
				vaultAddress,
				inputAmount
			);
			if (transaction) {
				const result = await tonConnectUI.sendTransaction(transaction);
				// handleTransactionConcluded(true, "Transaction complete", 200);

				if (result) {
					handleModal(result.boc);
					if (analyticsContext) {
						logEvent(analyticsContext.analytics, "contract", {
							contract: "LP_TO_VAULT",
							status: "Success",
							errMessage: "none",
						});
					}
				}

				return result;
			}
		} catch (err) {
			const error = err as Error;
			throw new Error(error.message);
		} finally {
			setLoading(false);
		}
	};
	const onClick = async () => {
		handleChainMessages();
	};

	return (
		<ButtonRectangular
			onClick={onClick}
			disabled={loading}
			text={loading ? "Pending..." : "Deposit to vault"}
		/>
	);
};

export default DepositLPButton;
