import { useEffect, useState } from "react";
import { IPrice } from "../types/interface";
import GetPrice from "../api/GetPrice";

const usePrice = () => {
	const [price, setPrice] = useState<IPrice[] | null>(null);
	const [loadingPrice, setLoading] = useState(true);
	const [errorPrice, setError] = useState<Error | string>("");
	const fetchPrice = async () => {
		try {
			const response = await GetPrice();
			if (response) {
				setPrice(response.data);
			}
		} catch (err) {
			setError(err instanceof Error ? err : new Error(String(err)));
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		fetchPrice();
	}, []);
	return { loadingPrice, errorPrice, price, fetchPrice };
};

export default usePrice;
