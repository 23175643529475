import { Col } from "../../../utils/styledComponents";
import ActionTabs from "./ActionTabs";

const PoolActionsWrapper = () => {
  return (
    <Col
      sx={{
        backgroundColor: "background.paper",
        width: "100%",
        borderRadius: "25px",
        boxShadow: "0px 2px 50px 0.05px rgba(0, 0, 0, 0.1)",
        // height: "30rem",
        gap: 2,
      }}
    >
      <ActionTabs />
    </Col>
  );
};

export default PoolActionsWrapper;
